import { ExportExcelButtonComponent } from './components/remote-update-tasks/pages/new-task/components/step-summary/components/export-excel-button/export-excel-button.component';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from "@angular-material-components/datetime-picker";
import { NgxMatMomentModule } from "@angular-material-components/moment-adapter";
import { NgxSliderModule } from "@angular-slider/ngx-slider";
import { A11yModule } from "@angular/cdk/a11y";
import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule } from "@angular/material-moment-adapter";
/******************* */
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatBadgeModule } from "@angular/material/badge";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatNativeDateModule, MatRippleModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
//---------------Services-------------------//
import { MatSliderModule } from "@angular/material/slider";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSortModule } from "@angular/material/sort";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatTreeModule } from "@angular/material/tree";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalInterceptorConfiguration, MsalModule, MsalService } from "@azure/msal-angular";
import { IPublicClientApplication, InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { RxReactiveDynamicFormsModule } from "@rxweb/reactive-dynamic-forms";
import { RxReactiveFormsModule } from "@rxweb/reactive-form-validators";
import { CKEditorModule } from "ckeditor4-angular";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { NgxCaptchaModule } from "ngx-captcha";
import { CookieService } from "ngx-cookie-service";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { MalihuScrollbarModule } from "ngx-malihu-scrollbar";
import { NgScrollbarModule } from "ngx-scrollbar";
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastNotificationsModule } from "ngx-toast-notifications";
import { NgxTrimDirectiveModule } from "ngx-trim-directive";
import { CalendarModule } from "primeng/calendar";
import { ProgressBarModule } from "primeng/progressbar";
import { TabViewModule } from "primeng/tabview";
import { ToastModule } from "primeng/toast";
import { TreeModule } from "primeng/tree";
import { TreeTableModule } from "primeng/treetable";
import { environment } from "src/environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AppConstant } from "./app.constant";
import { AlarmListComponent } from "./components/alarm-template/alarm-list/alarm-list.component";
import { AlarmTemplateAddComponent } from "./components/alarm-template/alarm-template-add/alarm-template-add.component";
import { AlarmTemplateListComponent } from "./components/alarm-template/alarm-template-list/alarm-template-list.component";
import { BreadcrumbComponent } from "./components/breadcrumb/breadcrumb.component";
import { CallbackComponent } from "./components/callback/callback.component";
import { AlertConfirmDialogComponent } from "./components/common/alert-confirm-dialog/alert-confirm-dialog.component";
import { ConfirmDialogComponent } from "./components/common/confirm-dialog/confirm-dialog.component";
import { AddEditMachineTypeComponent } from "./components/configuration-machinetype/add-edit-machine-type/add-edit-machine-type.component";
import { ConfigurationMachinetypeComponent } from "./components/configuration-machinetype/configuration-machinetype.component";
import { ConfigurationPricecardComponent } from "./components/configuration-pricecard/configuration-pricecard.component";
import { CustomToastComponent } from "./components/custom-toast/custom-toast.component";
import { DropdownDrawerComponent } from "./components/dropdown-drawer/dropdown-drawer.component";
//---------------Interceptor-------------------//
//---------------Components-------------------//
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { EntityAddComponent } from "./components/entity/entity-add/entity-add.component";
import { EntityConfigurationComponent } from "./components/entity/entity-configuration/entity-configuration.component";
import { EntityListComponent } from "./components/entity/entity-list/entity-list.component";
import { EntityMoveComponent } from "./components/entity/entity-move/entity-move.component";
import { MachineDataComponent } from "./components/entity/machine-data/machine-data.component";
import { MachineModelComponent } from "./components/entity/machine-model/machine-model.component";
import { TradeHoursComponent } from "./components/entity/trade-hours/trade-hours/trade-hours.component";
import { ViewSalesDetailsComponent } from "./components/entity/view-sales-details/view-sales-details.component";
import { ErrorPageComponent } from "./components/error/error-page/error-page.component";
import { FooterComponent } from "./components/footer/footer.component";
import { HeaderComponent } from "./components/header/header.component";
import { LandingComponent } from "./components/landing/landing/landing.component";
import { LeftnavmenuComponent } from "./components/leftnavmenu/leftnavmenu.component";
import { LoginComponent } from "./components/login/login.component";
import { MachineListComponent } from "./components/machines/machine-list/machine-list.component";
import { MachineMoveComponent } from "./components/machines/machine-move/machine-move.component";
import { MasterConfigurationComponent } from "./components/master-configuration/master-configuration.component";
import { NotificationComponent } from "./components/notification/notification.component";
import { PageNotFoundComponent } from "./components/page-not-found/page-not-found.component";
import { PriceCardAddComponent } from "./components/price-card/price-card-add/price-card-add.component";
import { PriceCardCoffeePricingComponent } from "./components/price-card/price-card-coffee-pricing/price-card-coffee-pricing.component";
import { PriceCardDrinksPricingComponent } from "./components/price-card/price-card-drinks-pricing/price-card-drinks-pricing.component";
import { PriceCardEntityMappingComponent } from "./components/price-card/price-card-entity-mapping/price-card-entity-mapping.component";
import { PriceCardInformationComponent } from "./components/price-card/price-card-information/price-card-information.component";
import { PriceCardListComponent } from "./components/price-card/price-card-list/price-card-list.component";
import { PriceCardMilkPricingComponent } from "./components/price-card/price-card-milk-pricing/price-card-milk-pricing.component";
import { PriceCardPreviewComponent } from "./components/price-card/price-card-preview/price-card-preview.component";
import { PriceCardSyrupsPricingComponent } from "./components/price-card/price-card-syrups-pricing/price-card-syrups-pricing.component";
import { PriceCardTemplateAddComponent } from "./components/pricecard-template/pricecard-template-add/pricecard-template-add.component";
import { PriceCardTemplateListComponent } from "./components/pricecard-template/pricecard-template-list/pricecard-template-list.component";
import { SalesListComponent } from "./components/sales/sales-list/sales-list.component";
import { UpdateHistoryComponent } from "./components/sales/update-history/update-history.component";
import { UpdateVendsComponent } from "./components/sales/update-vends/update-vends.component";
import { SitelayoutComponent } from "./components/sitelayout/sitelayout.component";
import { SOXAuditLogsListComponent } from "./components/sox-audit-logs/sox-audit-logs-list/sox-audit-logs-list.component";
import { UnauthorizedComponent } from "./components/unauthorized/unauthorized.component";
import { UserAuditListComponent } from "./components/user-audit-list/user-audit-list/user-audit-list.component";
import { UserVerificationReviewComponent } from "./components/user-verification-review/user-verification-review.component";
import { UserAddComponent } from "./components/user/user-add/user-add.component";
import { UserListComponent } from "./components/user/user-list/user-list.component";
import { SOXAuditLogConstant } from "./constant/audit-log.constant";
import { EntityConstant } from "./constant/entity.constants";
import { NumberOnlyDirective } from "./directives/numberonly.directive";
import { OneDigitDecimalNumberDirective } from "./directives/onedigitdecimalnumber.directive";
import { TooltipDirective } from "./directives/tooltip.directive";
import { TwoDigitDecimalNumberDirective } from "./directives/twodigitdecimalnumber.directive";
import { MasterLookupConstant } from "./enums/masterlookup.enum";
import { PriceCardTypeLookupConstant } from "./enums/pricecardtype.enum";
import { JwtInterceptor } from "./helper/jwt.interceptor";
import { TruncatePipe } from "./pipe/truncate-pipe";
import AlarmTemplateAddCanDeactivateGuard from "./services/alarm-template/alarm-deactivate.guard";
import { AlarmTemplateService } from "./services/alarm-template/alarm-template.service";
import { AuthService } from "./services/auth/auth.service";
import EntityCanDeactivateGuard from "./services/entity/entity-can-deactivate.guard";
import { EntityService } from "./services/entity/entity.service";
import MachineCanDeactivateGuard from "./services/entity/machine-can-deactivate.guard";
import UnsavedChangesCanDeactivateGuard from "./shared/unsaved-changes-can-deactivate.guard";
import { EventsService } from "./services/events/events.service";
import { MachineTypeService } from "./services/machine-type/machine-type.service";
import { MachineService } from "./services/machine/machine.service";
import { NotificationService } from "./services/notification/notification.service";
import { NotificationCountService } from "./services/notification/notificationCountService.service";
import { HighlightIconButtonComponent } from "./components/highlight-icon-button/highlight-icon-button.component";
import { FilterPipe } from './filter.pipe';
import { TaskEnableDrinksComponent } from "./components/remote-update-tasks/pages/new-task/components/step-task-properties/task-enable-drinks/task-enable-drinks.component";
import { TaskCertificateManagementComponent } from "./components/remote-update-tasks/pages/new-task/components/step-task-properties/task-certificate-management/task-certificate-management.component";
import { TaskPropertiesCardComponent } from "./components/remote-update-tasks/pages/new-task/components/step-task-properties/task-enable-drinks/task-properties-card/task-properties-card.component";
//---------------Components-------------------//

import { AddDrinksSetupComponent } from './components/drinks-setup/pages/add-drinks-setup/add-drinks-setup-page';
import { EditDrinksSetupComponent } from './components/drinks-setup/pages/edit-drinks-setup/edit-drinks-setup-page';
import { DrinksSetupComponent } from './components/drinks-setup/components/drink-list/drinks-list.component';
import { MarketCountrySelectionComponent } from './components/drinks-setup/components/market-country-selection/market-country-selection.component';
import { DrinkPropertiesComponent } from './components/drinks-setup/components/drink-properties/drink-properties.component';
import { TemplateNameComponent } from './components/drinks-setup/components/template-name/template-name.component';

//---------------Services-------------------//
import { MdePopoverModule } from '@material-extended/mde';
import { DataTablesModule } from "angular-datatables";
import { NgMarqueeModule } from "ng-marquee";
import { BarcodeCombinationComponent } from "./components/capsules-ingredients/barcode-combination/barcode-combination.component"; //[GD2-3703]
import { CapsulesIngredientsComponent } from "./components/capsules-ingredients/capsules-ingredients.component";
import { CapsulesProductnameComponent } from "./components/capsules-ingredients/capsules-productname/capsules-productname.component";
import { CupVolumesComponent } from "./components/capsules-ingredients/cup-volumes/cup-volumes.component"; //[GD2-3702]
import { CuptypeDefinitionComponent } from "./components/cuptype-definition/cuptype-definition.component";
import { BodyRemoteUpdateComponent } from "./components/remote-update-tasks/components/body-remote-update/body-remote-update.component";
import { CalendarRemoteUpdateComponent } from './components/remote-update-tasks/components/calendar-remote-update/calendar-remote-update.component';
import { SummaryCardComponent } from './components/remote-update-tasks/pages/new-task/components/step-summary/components/summary-card/summary-card.component';
import { SummaryInformationCardComponent } from './components/remote-update-tasks/pages/new-task/components/step-summary/components/information-card/information-card.component';
import { HeaderRemoteUpdateComponent } from "./components/remote-update-tasks/components/header-remote-update/header-remote-update.component";
import { AutocompleteComponent, AutocompleteModule, CostaCoffeeLibModule } from "@costa-coffee/pattern-lib";
import { TableRemoteUpdateComponent } from "./components/remote-update-tasks/components/table-remote-update/table-remote-update.component";
import { BodyFileRepositoryComponent } from "./components/remote-update-tasks/pages/file-repository/components/body-file-repository/body-file-repository";
import { HeaderFileRepositoryComponent } from "./components/remote-update-tasks/pages/file-repository/components/header-file-repository/header-file-repository";
import { FileRepositoryPageComponent } from "./components/remote-update-tasks/pages/file-repository/file-repository-page-remote-update";
import { NewTaskBodyComponent } from "./components/remote-update-tasks/pages/new-task/components/body-new-task/body-new-task.component";
import { DatepickerRemoteUpdateComponent } from './components/remote-update-tasks/pages/new-task/components/datepicker-remote-update/datepicker-remote-update.component';
import { MatCCStepperComponent } from "./components/remote-update-tasks/pages/new-task/components/mat-cc-stepper/mat-cc-stepper.component";
import { NewTaskHeaderComponent } from './components/remote-update-tasks/pages/new-task/components/new-task-header/new-task-header.component';
import { NewTaskPageComponent } from "./components/remote-update-tasks/pages/new-task/components/new-task-page-remote-update.component";
import { TableMachineSelectionComponent } from './components/remote-update-tasks/pages/new-task/components/step-machine-selection/components/table-machine-selection/table-machine-selection.component';
import { StatusColumnComponent } from './components/remote-update-tasks/pages/new-task/components/step-machine-selection/components/status-column/status-column.component';
import { StepMachineSelectionComponent } from './components/remote-update-tasks/pages/new-task/components/step-machine-selection/step-machine-selection.component';
import { TaskPackageComponent } from './components/remote-update-tasks/pages/new-task/components/step-task-properties/task-package/task-package.component';
import { StepScheduleComponent } from './components/remote-update-tasks/pages/new-task/components/step-schedule/step-schedule.component';
import { StepSummaryComponent } from './components/remote-update-tasks/pages/new-task/components/step-summary/step-summary.component';
import { StepTaskParametersComponent } from "./components/remote-update-tasks/pages/new-task/components/step-task-parameters/step-task-parameters.component";
import { RemoteUpdateTasksComponent } from "./components/remote-update-tasks/remote-update-tasks.component";
import { CreateRemoteUpdateTaskService } from "./components/remote-update-tasks/services/create/create-remote-update-task.service";
import { RemoteUpdateTaskExportCsvService } from "./components/remote-update-tasks/services/event-services/remote-update-task-export-csv.service";
import { RemoteUpdateTaskGetListService } from "./components/remote-update-tasks/services/get-list/remote-update-task-get-list.service";
import { GetPackageService } from "./components/remote-update-tasks/services/package/get-package.service";
import { AddEditTickerTapMessageComponent } from "./components/ticker-tap/add-edit-ticker-tap-message/add-edit-ticker-tap-message.component";
import { TickerTapMessageListComponent } from "./components/ticker-tap/ticker-tap-message-list/ticker-tap-message-list.component";
import { ConfigurationConstant } from "./constant/configuration.constant";
import { LocalDatePipe } from "./pipe/local-date-pipe.component";
import { PriceCardService } from "./services/price-card/price-card.service";
import { PriceCardTemplateService } from "./services/pricecard-template/pricecard-template.service";
import { RoleService } from "./services/role/role.service";
import { SalesService } from "./services/sales/sales.service";
import { SOXAuditLogsService } from "./services/sox-audit-logs/sox-audit-logs.service";
import UserAuditCanDeactivateGuard from "./services/user-audit/user-audit-deactivate.guard";
import { UserAuditService } from "./services/user-audit/user-audit.service";
import UserCanDeactivateGuard from "./services/user/user-deactivate.guard";
import { UserService } from "./services/user/user.service";
import { AzureService } from "./services/azure/azure.service";
import { RemoteUpdateTaskSummaryComponent } from './components/remote-update-tasks/pages/summary/remote-update-task-summary.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { TablePackagesComponent } from './components/remote-update-tasks/pages/file-repository/components/table-packages/table-packages.component';
import { UploadFilePackagesComponent } from './components/remote-update-tasks/pages/file-repository/components/upload-file-packages/upload-file-packages.component';
import { UploadComponent } from './components/upload/upload.component';
import { PriceCardEntityMappingV2Component } from "./components/price-card/price-card-entity-mapping-v2/price-card-entity-mapping-v2.component";
import { LowAdminService } from "./services/low-admin/low-admin.service";

import { RemoteUpdateService } from "./services/remote-update/remote-update.service";
import { SessionStorageService } from "./services/session-storage/session-storage.service";
import { TaskPropertiesService } from "./components/remote-update-tasks/services/task-properties/task-properties.service";
import { StepTaskPropertiesComponent } from "./components/remote-update-tasks/pages/new-task/components/step-task-properties/step-task-properties.component";
import { DrinkSetupPropositionTypeService } from './components/drinks-setup/services/drink-setup-proposition-type.service';
//---------------Services-------------------//

export const protectedResourceMap: [string, string[]][] = [[environment.msal.costaExpressUrl, [environment.msal.apiscope]]];

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.msal.clientId, //'6226576d-37e9-49eb-b201-ec1eeb0029b6',
      authority: environment.msal.authority, //"https://login.microsoftonline.com/6511efd3-03fb-449b-9a07-00ccbcc060ae/oauth2/v2.0/authorize",//,
      redirectUri: environment.msal.redirectUrl,
      postLogoutRedirectUri: environment.msal.postLogoutRedirectUri,
      navigateToLoginRequestUrl: true,
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(environment.msal.costaExpressUrl, [environment.msal.apiscope]); // Prod environment. Uncomment to use.

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [environment.msal.loginscope],
    },
  };
}

export function HttpLoaderFactory(httpClient: HttpClient) { }

@NgModule({
  declarations: [
    AppComponent,
    CallbackComponent,
    DashboardComponent,
    EntityAddComponent,
    EntityListComponent,
    EntityMoveComponent,
    MachineMoveComponent,
    LoginComponent,
    SitelayoutComponent,
    HeaderComponent,
    FooterComponent,
    LeftnavmenuComponent,
    BreadcrumbComponent,
    TooltipDirective,
    TwoDigitDecimalNumberDirective,
    OneDigitDecimalNumberDirective,
    NumberOnlyDirective,
    ConfirmDialogComponent,
    AlertConfirmDialogComponent,
    MachineListComponent,
    MachineDataComponent,
    TradeHoursComponent,
    PriceCardTemplateAddComponent,
    PriceCardTemplateListComponent,
    PriceCardAddComponent,
    PriceCardListComponent,
    PriceCardInformationComponent,
    PriceCardDrinksPricingComponent,
    PriceCardCoffeePricingComponent,
    PriceCardMilkPricingComponent,
    PriceCardSyrupsPricingComponent,
    PriceCardEntityMappingComponent,
    PriceCardEntityMappingV2Component,
    PriceCardPreviewComponent,
    MasterConfigurationComponent,
    TruncatePipe,
    ConfigurationPricecardComponent,
    UnauthorizedComponent,
    SalesListComponent,
    UpdateHistoryComponent,
    UpdateVendsComponent,
    EntityConfigurationComponent,
    UserAddComponent,
    UserListComponent,
    ViewSalesDetailsComponent,
    ErrorPageComponent,
    LandingComponent,
    NotificationComponent,
    MachineModelComponent,
    PageNotFoundComponent,
    SOXAuditLogsListComponent,
    UserVerificationReviewComponent,
    AlarmTemplateListComponent,
    AlarmTemplateAddComponent,
    AlarmListComponent,
    UserAuditListComponent,
    ConfigurationMachinetypeComponent,
    AddEditMachineTypeComponent,
    TickerTapMessageListComponent,
    AddEditTickerTapMessageComponent,
    LocalDatePipe,
    CuptypeDefinitionComponent,
    CapsulesIngredientsComponent,
    BarcodeCombinationComponent, //[GD2-3703]
    CupVolumesComponent, //[GD2-3702]
    CapsulesProductnameComponent, //GD2-3704
    RemoteUpdateTasksComponent,
    HeaderRemoteUpdateComponent,
    BodyRemoteUpdateComponent,
    TableRemoteUpdateComponent,
    BodyFileRepositoryComponent,
    HeaderFileRepositoryComponent,
    FileRepositoryPageComponent,
    MatCCStepperComponent,
    NewTaskBodyComponent,
    NewTaskPageComponent,
    StepTaskParametersComponent,
    NewTaskHeaderComponent,
    TaskPackageComponent,
    StepMachineSelectionComponent,
    StepSummaryComponent,
    TableMachineSelectionComponent,
    StatusColumnComponent,
    CalendarRemoteUpdateComponent,
    SummaryCardComponent,
    SummaryInformationCardComponent,
    ExportExcelButtonComponent,
    StepScheduleComponent,
    DatepickerRemoteUpdateComponent,
    DropdownDrawerComponent,
    TablePackagesComponent,
    UploadFilePackagesComponent,
    HighlightIconButtonComponent,
    RemoteUpdateTaskSummaryComponent,
    DialogComponent,
    FilterPipe,
    UploadComponent,
    AddDrinksSetupComponent,
    EditDrinksSetupComponent,
    DrinksSetupComponent,
    MarketCountrySelectionComponent,
    DrinkPropertiesComponent,
    TemplateNameComponent,
    TaskPropertiesCardComponent,
    TaskEnableDrinksComponent,
    TaskCertificateManagementComponent,
    StepTaskPropertiesComponent
  ],
  imports: [
    NgMultiSelectDropDownModule.forRoot(),
    MdePopoverModule,
    DataTablesModule,
    CostaCoffeeLibModule,
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    NgxSpinnerModule,
    AppRoutingModule,
    TreeTableModule,
    TreeModule,
    CalendarModule,
    ProgressBarModule,
    TabViewModule,
    MatTabsModule,
    MatFormFieldModule,
    MatSelectModule,
    A11yModule,
    MatInputModule,
    ToastModule,
    FormsModule,
    ReactiveFormsModule,
    RxReactiveFormsModule,
    MatSliderModule,
    RxReactiveDynamicFormsModule,
    MatButtonModule,
    MatIconModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatRippleModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    HttpClientModule,
    NgMultiSelectDropDownModule.forRoot(),
    MsalModule,
    NgxSliderModule,
    //ToastNotificationsModule,
    ToastNotificationsModule.forRoot({ component: CustomToastComponent }),
    InfiniteScrollModule,
    NgxCaptchaModule,
    MalihuScrollbarModule.forRoot(),
    // TranslateModule.forRoot({
    //   defaultLanguage: 'en',
    //   loader: {
    //     provide: TranslateLoader,
    //     useFactory: HttpLoaderFactory,
    //     deps: [HttpClient]
    //   }
    // }),
    NgScrollbarModule,
    MatMomentDateModule,
    NgxMatMomentModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    NgxTrimDirectiveModule,
    CKEditorModule,
    NgMarqueeModule,
    AutocompleteModule,
  ],
  exports: [NgScrollbarModule, TooltipDirective, TwoDigitDecimalNumberDirective, OneDigitDecimalNumberDirective, NumberOnlyDirective],
  providers: [
    AuthService,
    EntityService,
    MachineService,
    SalesService,
    EventsService,
    PriceCardService,
    CookieService,
    PriceCardTemplateService,
    DrinkSetupPropositionTypeService,
    RoleService,
    UserService,
    LowAdminService,
    NotificationService,
    NotificationCountService,
    EntityCanDeactivateGuard,
    MachineCanDeactivateGuard,
    UnsavedChangesCanDeactivateGuard,
    UserCanDeactivateGuard,
    AlarmTemplateAddCanDeactivateGuard,
    UserAuditCanDeactivateGuard,
    AppConstant,
    MasterLookupConstant,
    PriceCardTypeLookupConstant,
    SOXAuditLogsService,
    SOXAuditLogConstant,
    EntityConstant,
    AlarmTemplateService,
    UserAuditService,
    MachineTypeService,
    RemoteUpdateTaskGetListService,
    RemoteUpdateTaskExportCsvService,
    ConfigurationConstant,
    CreateRemoteUpdateTaskService,
    GetPackageService,
    SessionStorageService,
    TaskPropertiesService,
    RemoteUpdateService,
    //{ provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: MsalInterceptor,
    //   multi: true
    // },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
  ],
  entryComponents: [EntityMoveComponent, AutocompleteComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
