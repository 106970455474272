import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';

import { CreateRemoteUpdateTaskRequest } from 'src/app/components/remote-update-tasks/dtos/create-remote-update-task.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-new-task-header',
  templateUrl: './new-task-header.component.html',
  styleUrls: ['./new-task-header.component.css' , '../new-task-step-common.css']
})
export class NewTaskHeaderComponent implements OnInit {

  @Input() taskId : string = '';
  @Input() newTaskRequest : CreateRemoteUpdateTaskRequest = new CreateRemoteUpdateTaskRequest();

  confirmDialog: boolean = false;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  public openConfirmDialog() {
    this.confirmDialog= true;
  }

  public cancelTaskCreation() {
    this.closeConfirmDialog();

    this.router.navigate(['/task-management-overview']);
  }

  public closeConfirmDialog() {
    this.confirmDialog = false;
  }

  public isSummaryPage() : boolean {
    if (this.taskId != '' && this.taskId != undefined && this.taskId != null)
      return true;

    return false;
  }
}
