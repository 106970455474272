import { Injectable } from '@angular/core';
import { DialogConfiguration } from './dialog-configuration.interface';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  show: boolean = false;
  configuration : DialogConfiguration;

  constructor() { }

  showErrorDialog() : void {
    this.configuration = {
      header: 'System Error',
      body: 'Something went wrong with your request, try again in a few minutes',
      buttons: [
        {
          id: 'error-dialog-ok-button',
          text: 'OK',
          variant: 'primary',
          onClickEvent: () => this.closeDialog()
        }
      ]
    }
    this.show = true;
  };

  /**
   * Render a dialog with YES and NO options
   * @param header Header of dialog
   * @param body Body of dialog
   * @param yesClickEvent Callback event when YES option is clicked
   * @param noClickEvent Callback event when NO option is clicked
   */
  showYesNoDialog(header : string, body : string, yesClickEvent? : any, noClickEvent? : any) : void {
    this.configuration = {
      header: header,
      body: body,
      buttons: [
        {
          id: 'dialog-yes-button',
          text: 'Yes',
          variant: 'outline',
          onClickEvent: () => yesClickEvent()
        },
        {
          id: 'dialog-no-button',
          text: 'No',
          variant: 'primary',
          onClickEvent: () => noClickEvent()
        },
      ]
    }
    this.show = true;
  };

  closeDialog() {
    this.show = false;
  }
}
