import * as moment from 'moment';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { CreateRemoteUpdateTaskRequest } from 'src/app/components/remote-update-tasks/dtos/create-remote-update-task.model';
import { RemoteUpdateTaskDeploymentType } from 'src/app/components/remote-update-tasks/enums/remote-update-task-deployment-type.enum';
import { RemoteUpdateTaskStatus } from 'src/app/components/remote-update-tasks/enums/remote-update-task-status.enum';

@Component({
  selector: 'app-step-schedule',
  templateUrl: './step-schedule.component.html',
  styleUrls: ['./../new-task-step-common.css', './step-schedule.component.css']
})
export class StepScheduleComponent implements OnInit {
  @Output() nextStepEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() backStepEvent: EventEmitter<any> = new EventEmitter<any>();
  @Input() newTaskRequest: CreateRemoteUpdateTaskRequest = new CreateRemoteUpdateTaskRequest();

  public isImmediateDeployment: boolean = false;
  public allowToggle: boolean = true
  public datePickerDisabled: boolean = false;
  public datePickerMinDate: Date = new Date();
  public datePickerComponentValue: Date;

  public timePickerDisabled: boolean = false;
  public timePickerMinTime: string;
  public timePickerValue: string = '';

  public showDialog: boolean = false;
  public dialogHeader: string = '';
  public dialogBody: string = '';

  public scheduleFormGroup: FormGroup = new FormGroup({
    datePicker: new FormControl({ value: '', disabled: false }, Validators.required),
    timePicker: new FormControl({ value: '', disabled: true }, Validators.required)
  })

  ngOnInit(): void { }

  configure (isImmediateDeployment = false, allowToggle = true): void {
    this.allowToggle = true
    if (this.isImmediateDeployment !== isImmediateDeployment) {
      this.changeToggle()
    }
    if (isImmediateDeployment) {
      this.updateTime()
    }
    this.allowToggle = allowToggle
  }

  updateTime() : void {
    let date = new Date();
    let currentTime = moment(date).format("hh:mm A");

    this.scheduleFormGroup.get('datePicker').setValue(date);
    this.scheduleFormGroup.get('timePicker').setValue(currentTime);

    this.scheduleFormGroup.get('datePicker').disable();
    this.scheduleFormGroup.get('timePicker').disable();
    this.scheduleFormGroup.markAsUntouched();
  }

  changeToggle() : void {
    if(!this.allowToggle) return
    this.isImmediateDeployment = !this.isImmediateDeployment;

    if (this.isImmediateDeployment) {
      this.updateTime()
      return;
    }
    this.scheduleFormGroup.get('datePicker').setValue('');
    this.scheduleFormGroup.get('timePicker').setValue('');
    this.scheduleFormGroup.get('datePicker').enable();
  }

  private validate() : void {

    const header = 'Selection Error';

    const datePicker = this.scheduleFormGroup.get('datePicker');
    const timePicker = this.scheduleFormGroup.get('timePicker');

    if (datePicker.invalid && datePicker.hasError('required') ||
      timePicker.invalid && timePicker.hasError('required')) {
      this.handleDialog(header, 'Please complete all the required fields.', true);
      this.scheduleFormGroup.markAllAsTouched();
      timePicker.updateValueAndValidity();
      return;
    }

    this.validateDate();

    this.validatePastTime(header);
  }

  public validateDate(): void {
    this.scheduleFormGroup.get('datePicker').markAsTouched();
    const datePicker = this.scheduleFormGroup.get('datePicker');

    this.scheduleFormGroup.get('timePicker').setValue('');

    if (datePicker.invalid && datePicker.hasError('matDatepickerParse')) {
      this.handleDialog('Selection Error', 'Invalid date format. Please use DD/MM/YYYY format instead.', true);
      this.scheduleFormGroup.get('datePicker').setValue('');
      this.scheduleFormGroup.get('timePicker').markAsUntouched();
      this.scheduleFormGroup.get('timePicker').disable();
      return;
    }

    if (datePicker.invalid && datePicker.hasError('matDatepickerMin')) {
      this.handleDialog('Selection Error', 'The selected date is in the past. Please choose a future date.', true);
      this.scheduleFormGroup.get('datePicker').setValue('');
      this.scheduleFormGroup.get('timePicker').markAsUntouched();
      this.scheduleFormGroup.get('timePicker').disable();
      return;
    }

    if (datePicker.valid)
      this.scheduleFormGroup.get('timePicker').enable();
  }

  private validatePastTime(header: string) {
    const currentDate = moment(new Date());

    const datePicker = this.scheduleFormGroup.get('datePicker');
    const timePicker = this.scheduleFormGroup.get('timePicker');

    const datePickerValue = datePicker.value.format("YYYY-MM-DD");
    const currentDateValue = currentDate.format("YYYY-MM-DD");

    const currentHour = Number(currentDate.format("HHmm"));
    const timePickerValues = timePicker.value.split(" ");
    const timePickerHour = timePickerValues[0].split(":");
    const timePickerPeriod = timePickerValues[1];

    let hourCompare;

    if (timePickerPeriod == "pm")
      hourCompare = Number((+timePickerHour[0] + 12) + timePickerHour[1]);
    else
      hourCompare = Number(timePickerHour[0] + timePickerHour[1]);

    if (moment(currentDateValue).isAfter(datePickerValue) ||
        (moment(currentDateValue).isSame(datePickerValue) && (hourCompare < currentHour))) {
      this.handleDialog(header, 'The time selected is in the past. Please choose a future time.', true);
      this.scheduleFormGroup.get('timePicker').markAsTouched();
      return false;
    }

    return true;
  }

  dateChangeEvent(event : any) : void {
    this.scheduleFormGroup.get('timePicker').setValue('');
    this.scheduleFormGroup.markAsUntouched();

    if(event.value)
      this.scheduleFormGroup.get('timePicker').enable();
    else
      this.scheduleFormGroup.get('timePicker').disable();
  }

  handleDialog(header : string = '', body : string = '', show : boolean = false) : void {
    this.showDialog = show;
    this.dialogHeader = header;
    this.dialogBody = body;
  }

  toDateTime(datePickerValue: moment.Moment, timePickerValue: string): string {
    const month = (datePickerValue.month() + 1).toString().padStart(2, '0');
    let strDate = `${datePickerValue.year()}-${month}-${datePickerValue.date()} ${timePickerValue}`
    let date = new Date(strDate);

    const year = date.getFullYear();
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }

  nextStep(): void {
    this.newTaskRequest.TaskStatus = RemoteUpdateTaskStatus.Pending;

    if (this.isImmediateDeployment) {
      const dateTime = moment().format('YYYY-MM-DDTHH:mm');
      this.newTaskRequest.ScheduleOn = dateTime;
      this.newTaskRequest.DeploymentType = RemoteUpdateTaskDeploymentType.Immediate;
      this.nextStepEvent.emit();

      return;
    }

    switch (this.scheduleFormGroup.status) {
      case "VALID":
        let valid = this.validatePastTime("Selection Error");
        if (valid) {
          const dateTime = this.toDateTime(
            this.scheduleFormGroup.get('datePicker').value,
            this.scheduleFormGroup.get('timePicker').value
          );
          this.newTaskRequest.ScheduleOn = dateTime;
          this.newTaskRequest.DeploymentType = RemoteUpdateTaskDeploymentType.Scheduled;
          this.nextStepEvent.emit();
        }
        break;
      case "INVALID":
        this.validate();
        break;
    }
  }

  backStep(): void {
    this.backStepEvent.emit();
  }

  isPastTime(event: boolean) {
    if (event) {
      this.dialogHeader = "Selection Error";
      this.dialogBody = "The time selected is in the past. Please choose a future time";
      this.showDialog = true;
    }
  }
}
