import * as moment from 'moment';
import { Component,  EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { CreateRemoteUpdateTaskRequest } from 'src/app/components/remote-update-tasks/dtos/create-remote-update-task.model';
import { CreateRemoteUpdateTaskService } from 'src/app/components/remote-update-tasks/services/create/create-remote-update-task.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { RemoteUpdateTaskStatus } from 'src/app/components/remote-update-tasks/enums/remote-update-task-status.enum';
import { Router } from '@angular/router';
import { Toaster } from 'ngx-toast-notifications';
import { RemoteUpdateTaskDeploymentType } from 'src/app/components/remote-update-tasks/enums/remote-update-task-deployment-type.enum';
import { RemoteUpdateCancelTaskService } from 'src/app/components/remote-update-tasks/services/cancel-task/remote-update-cancel-task.service';
import { RemoteUpdateCancelTaskRequest } from 'src/app/components/remote-update-tasks/dtos/remote-update-cancel-task-request';
import { RemoteUpdateTaskType } from 'src/app/components/remote-update-tasks/enums/remote-update-task-type.enum';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { RemoteUpdateEntityDeployLevel } from 'src/app/components/remote-update-tasks/enums/remote-update-entity-deploy-level.enum';
import { SummaryCardComponent } from './components/summary-card/summary-card.component';
import { RemoteUpdateMachineGetListService } from 'src/app/components/remote-update-tasks/services/get-list/remote-update-machine-get-list.service';
import { GetRemoteUpdateMachineList } from 'src/app/components/remote-update-tasks/dtos/get-remote-update-machine-list.model';
import { RemoteUpdateTaskMachineType } from 'src/app/components/remote-update-tasks/dtos/remote-update-task-machine';

@Component({
  selector: 'app-step-summary',
  templateUrl: './step-summary.component.html',
  styleUrls: ['./../new-task-step-common.css', './step-summary.component.css']
})
export class StepSummaryComponent implements OnInit {
  @Input() newTaskRequest : CreateRemoteUpdateTaskRequest = new CreateRemoteUpdateTaskRequest();
  @Input() taskId : number = 0;

  @Output() backStepEvent: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild("leftSummary") private leftSummary: SummaryCardComponent;
  @ViewChild("rightSummary") private rightSummary: SummaryCardComponent;

  public totalMachinesLabel : string = '';
  public totalMachines : number = 0;
  public package: string = '';
  public deploymentType: RemoteUpdateTaskDeploymentType = RemoteUpdateTaskDeploymentType.Scheduled;
  public taskStatus: RemoteUpdateTaskStatus = RemoteUpdateTaskStatus.Scheduled;
  public primarySubtitle: string;
  public shouldShowCalendar: boolean;
  public hierarchyLevel: string = 'Market';

  public calendarDate: Date = new Date();
  public calendarDataLabel: string = '';
  public calendarHour : string = '';
  public calendarName: String = 'Scheduled for';

  public showDialog : boolean = false;
  public dialogHeader : string = '';
  public dialogBody : string = '';

  public showSnackBar : boolean = false;
  public snackBarHeader : string = '';
  public snackBarMessage: string = '';
  public footNote : string = '';

  public enableCalendarLabel: boolean = false;
  public enablePrimaryExportButton: boolean = false;
  public enableSecondaryExportButton: boolean = false;
  public enableRightSummaryCard : boolean = false;
  private canCancelTaskStatus = [
    RemoteUpdateTaskStatus.Pending,
    RemoteUpdateTaskStatus.Scheduled,
    RemoteUpdateTaskStatus.InProgress,
  ];


  public isDisabledExportButton : boolean = false;

  constructor(
    private remoteUpdateMachineListService: RemoteUpdateMachineGetListService,
    private cookieService: CookieService,
    private router: Router,
    private service: CreateRemoteUpdateTaskService,
    private cancelTaskService: RemoteUpdateCancelTaskService,
    private spinner: NgxSpinnerService,
    private toaster: Toaster,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {

  }

  ngOnChanges() : void {
    this.getNewTaskInformation();
  }

  public getNewTaskInformation() : void {
    this.primarySubtitle = this.newTaskRequest.TaskType === RemoteUpdateTaskType.CertificateManagement ? 'Immediate Deployment' :  'Task Status: ' + this.newTaskRequest.TaskStatus
    this.shouldShowCalendar = ![RemoteUpdateTaskType.CertificateManagement, RemoteUpdateTaskType.EnableDisableDrinks].includes(this.newTaskRequest.TaskType)
    this.enableSecondaryExportButton = this.isEnableDisableDrinksTaskType();


    this.newTaskRequest.CreatedBy = this.cookieService.get('CE_userId');

    this.package = this.newTaskRequest.Package;
    this.deploymentType = this.newTaskRequest.DeploymentType;
    this.taskStatus = this.newTaskRequest.TaskStatus;
    if (this.newTaskRequest.TaskType === RemoteUpdateTaskType.EnableDisableDrinks) {
      this.newTaskRequest.EntityDeployLevel = RemoteUpdateEntityDeployLevel.Machine;
    }
    this.hierarchyLevel = this.newTaskRequest.EntityDeployLevel;

    this.calendarDate = new Date(this.newTaskRequest.ScheduleOn);
    this.calendarDataLabel = moment(this.newTaskRequest.ScheduleOn).format("DD/MM/YYYY");
    this.calendarHour = moment(this.newTaskRequest.ScheduleOn).format("h:mm a");

    if (this.newTaskRequest.Machines == undefined || this.newTaskRequest.Machines == null || this.newTaskRequest.Machines.length == 0)
      this.totalMachines = this.newTaskRequest.TotalOfMachines;
    else
      this.totalMachines = this.newTaskRequest.Machines.length;

    this.totalMachinesLabel = this.totalMachines.toLocaleString();
    this.enableCalendarLabel = this.isEnableCalendarLabel();
    this.enablePrimaryExportButton = this.isSummaryPage();
    this.enableRightSummaryCard = this.isEnableRightSummaryCard();
    this.footNote = this.getFootNote();
    this.leftSummary.loadSummaryCardsModel();
    this.rightSummary.loadSummaryCardsModel();
  }

  public handleDialog(header : string = '', body : string = '', show : boolean = false) : void {
    this.showDialog = show;
    this.dialogHeader = header;
    this.dialogBody = body;
  }

  public showCalendar(): boolean {
    return this.newTaskRequest.TaskType != RemoteUpdateTaskType.EnableDisableDrinks;
  }

  public getFootNote(): string {
    const messages = {
      [RemoteUpdateTaskType.EnableDisableDrinks]: 'If a drink is not set up on the machine, any changes to<br>enable or disable will not apply.',
      [RemoteUpdateTaskType.SoftwareUpdate]: 'The update will be active at the local time of the machine',
      [RemoteUpdateTaskType.FirmwareUpdate]: 'The update will be active at the local time of the machine'
    }
    return messages[this.newTaskRequest.TaskType] || ''
  }

  public async getMoreInfo (): Promise<boolean> {
    return new Promise((resolve) => {
      const queryParams = new Map()
      queryParams.set('propositionType', this.newTaskRequest.PropositionType)
      this.remoteUpdateMachineListService.postRemoteMachineUpdate<GetRemoteUpdateMachineList>({
        queryParams,
        limit: 99999999,
        page: 0,
        levels: {
          machineId: this.newTaskRequest.Machines,
          Level1Ids: this.newTaskRequest.Level1.map((m) => m.id),
          Level2Ids: this.newTaskRequest.Level2.map((m) => m.id),
          Level3Ids: this.newTaskRequest.Level3.map((m) => m.id)
        },
        Type: RemoteUpdateTaskMachineType.GetAllCertificates
      }).subscribe(
        (response) => {
          this.newTaskRequest.MachinesTemp.push(...response.data.map(({machineId, certificateExpiryDate, certificateStatus}) => ({
            machineId,
            certExpiryDate: certificateExpiryDate,
            certStatus: certificateStatus	
          })))
          resolve(true)
        },
        (err) => {
          this.spinner.hide();
          this.toaster.open({
            text: err.message,
            type: 'danger',
            position: 'top-right',
            duration: 10000,
          });
          resolve(false)
        },
      );
    })
  }
  public async confirmTaskCreation() : Promise<void> {
    this.showDialog = false;
    this.spinner.show();
    // TODO: remove this when implementation happen at backend GD2-14353
    if (this.newTaskRequest.TaskType === RemoteUpdateTaskType.CertificateManagement) {
      this.newTaskRequest.MachinesTemp = []
      const resolved = await this.getMoreInfo()
      if(!resolved) { 
        this.spinner.hide();
        return
      }
    }
    this.service.createNewTask(this.newTaskRequest).subscribe(
      next => {
        if (next.taskId != null || next.taskId != undefined) {
          this.router.navigate(['/task-management-overview']);
        }
      },
      error => {
        this.toaster.open({
          text: error.title,
          type: 'danger',
          position: 'top-right',
          duration: 10000,
        });
      },
      () => this.spinner.hide()
    )
  }

  public cancelTaskCreation() : void {
    this.showDialog = false;
  }

  public cancelInProgressTask() : void {
    this.showDialog = false;
    this.spinner.show();

    const request : RemoteUpdateCancelTaskRequest = {
      taskId: this.taskId,
      userId: this.cookieService.get('CE_userId')
    };

    this.cancelTaskService.execute(request).subscribe(
      next => {
        if (next.success) {
          this.newTaskRequest.TaskStatus = RemoteUpdateTaskStatus.CancellationPending;
          this.taskStatus = this.newTaskRequest.TaskStatus;
        }
        else {
          this.showSnackBar = true;
          this.snackBarMessage = next.message;
        }
      },
      error => {
          this.showSnackBar = true;
          this.snackBarMessage = error.error.message;
      },
      () => this.spinner.hide()
    )

  }

  public closeSnackBar() : void {
    this.showSnackBar = false;
  }

  public isEnableDisableDrinksTaskType(): boolean {
    return this.newTaskRequest.TaskType == RemoteUpdateTaskType.EnableDisableDrinks;
  }
  public isCertificateManagement(): boolean {
    return this.newTaskRequest.TaskType == RemoteUpdateTaskType.CertificateManagement;
  }

  public isEnableRightSummaryCard() : boolean {
    return this.taskStatus != RemoteUpdateTaskStatus.InProgress || this.isEnableDisableDrinksTaskType() || this.isCertificateManagement();
  }

  public isEnableCalendarLabel() : boolean {
    return this.isSummaryPage() && !this.isEnableRightSummaryCard();
  }

  public isSummaryPage() : boolean {
    if (this.taskId != 0 && this.taskId != null && this.taskId != undefined)
      return true;

    return false;
  }

  public enableCancelButton() : boolean {
    if (!this.isSummaryPage()) return false
    if (this.newTaskRequest.TaskType === RemoteUpdateTaskType.CertificateManagement) return false
    return this.canCancelTaskStatus.includes(this.taskStatus)
  }

  public backStep() : void {
    if (this.isSummaryPage()) {
      this.router.navigate(['/task-management-overview']);
      return;
    }
    this.backStepEvent.emit();
  }
}
