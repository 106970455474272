import { Component, Input, OnInit } from '@angular/core';
import { DialogService } from './dialog.service';

@Component({
  selector: 'grid-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})
export class DialogComponent implements OnInit {

  constructor(public service: DialogService) { }

  ngOnInit(): void {}
}
