import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Toaster } from 'ngx-toast-notifications';
import { Observable } from 'rxjs';
import { PropositionMasterEnum } from 'src/app/enums/propositionMaster.enum';
import { EntityService } from 'src/app/services/entity/entity.service';
import { MachineService } from 'src/app/services/machine/machine.service';
import { RoleService } from 'src/app/services/role/role.service';
import { MachineTypeComponents } from './MachineTypeComponents';
import { PageTypeEnum } from "src/app/enums/pageType.enum";
import { AzureService } from 'src/app/services/azure/azure.service';

@Component({
  selector: 'app-machine-model',
  templateUrl: './machine-model.component.html',
  styleUrls: ['./machine-model.component.css'],
})
export class MachineModelComponent implements OnInit {
  @ViewChild('confirmDialogTemplate') confirmDialogTemplate: TemplateRef<any>;
  machineModelForm: FormGroup;
  initialFormValues: Record<string, any> = {}
  templateAutocomplete: Observable<string[]>;
  @Output() tabChange = new EventEmitter<number>();
  templates: any = [];
  radioObj: any = {
    isEditable: true,
  };
  screenType = 'add';
  entityId = '';
  machineId = '';
  machineTypes: any[] = [];
  machineModelDetails = {};
  dialogRef: MatDialogRef<any>;
  dialogTexts = {
    successTitle: '',
    cancelTitle: '',
    message: '',
    deviceName: '',
    status: '',
    title: '',
    type: '',
  };
  isComponentHide = true;
  levelNumber = 8;
  radioOptions: any = MachineTypeComponents;
  hasPageLoad = false;
  isDisabled = false;
  selectedPropositionType: string = '';
  PropositionMasterEnum = PropositionMasterEnum;
  valuesSetToForm: boolean = false;
  machineModelList: any[] = [];
  excludedMachineModelList: string[] = ['COSTA M300PS'];
  capsulesModelDetails = {
    waterConnection: '',
    milkType: '', //[GD2-3787]
  };
  disableLockdownFieldsForConcessionsFT = false;
  enableGridImprovementsFT = false;

  constructor(
    public roleService: RoleService,
    public dialog: MatDialog,
    private toaster: Toaster,
    private spinner: NgxSpinnerService,
    private router: Router,
    private machineService: MachineService,
    private activatedRoute: ActivatedRoute,
    private entityService: EntityService,
    private azureService: AzureService,
  ) {
    this.activatedRoute.params.subscribe((params) => {
      if (params.entityId) {
        this.entityId = params.entityId;
        this.machineId = params.entityId;
      }
      if (params.machineId) {
        this.machineId = params.machineId;
        this.entityId = params.machineId;
      }
      if (params.levelNumber) {
        this.levelNumber = params.levelNumber;
      }
    });
  }

  async ngOnInit(): Promise<void> {
    this.identifyPropositionType(); //GD2-3459
    this.createFormGroup();
    await this.setFeatureFlagsConfigurations();
    this.getMachineModelDetails();
    this.activatedRoute.queryParams.subscribe((queryParams) => {
      if (queryParams.screenType) this.screenType = queryParams.screenType;
      if (this.roleService.objRole.isSiteSupportL1 || this.roleService.objRole.isSiteSupportL2 || this.roleService.objRole.isSiteSupportL3) {
        this.screenType = 'read';
      }
      if (this.screenType == 'read') {
        this.isDisabled = true;
      }
    });
    await this.loadFeatureToggles();
    this.initialFormValues = this.machineModelForm.getRawValue()
  }

  private async loadFeatureToggles() {
    this.disableLockdownFieldsForConcessionsFT = await this.azureService.isDisableConcessionLockdownPropertiesFT();
  }

  private async setFeatureFlagsConfigurations() {
    this.enableGridImprovementsFT = await this.azureService.isEnableGridImprovementsFT();
  }

  getPageType(): PageTypeEnum {
    return this.screenType as PageTypeEnum;
  }

  hasModelCode(): boolean {
    return this.machineModelDetails['isCodeGenerated'] ||
      this.machineModelDetails['machineModelType']?.trim() != 'Proprietary' ||
      this.selectedPropositionType?.toLowerCase() == PropositionMasterEnum.Capsules.toLowerCase() ||
      this.selectedPropositionType?.toLowerCase() == PropositionMasterEnum.Autonomous.toLowerCase();
  }

  hasButtonSave(): boolean {
    return (this.getPageType() == PageTypeEnum.Move || this.getPageType() == PageTypeEnum.Edit) &&
      this.selectedPropositionType?.toLowerCase() != PropositionMasterEnum.Capsules.toLowerCase() &&
      this.selectedPropositionType?.toLowerCase() != PropositionMasterEnum.Autonomous.toLowerCase();
  }

  hasButtonEdit(): boolean {
    return this.getPageType() == PageTypeEnum.Read &&
      (this.roleService.objRole.isMarketAdmin ||
        this.roleService.objRole.isSystemAdmin ||
        this.roleService.objRole.isGlobalMarketAdmin ||
        this.roleService.objRole.isLevel2Admin ||
        this.roleService.objRole.isLevel3Admin) &&
      this.selectedPropositionType?.toLowerCase() != PropositionMasterEnum.Capsules.toLowerCase() &&
      this.selectedPropositionType?.toLowerCase() != PropositionMasterEnum.Autonomous.toLowerCase();
  }

  hasButtonCancel(): boolean {
    return (this.getPageType() == PageTypeEnum.Add ||
      (this.selectedPropositionType?.toLowerCase() != PropositionMasterEnum.Capsules.toLowerCase() &&
        this.selectedPropositionType?.toLowerCase() != PropositionMasterEnum.Autonomous.toLowerCase()));
  }

  isPropositionCapsules(): boolean {
    return this.machineModelDetails['machineModelName']?.trim() != '' &&
      this.selectedPropositionType?.toLowerCase() == PropositionMasterEnum.Capsules.toLowerCase();
  }

  isPropositionAutonomous(): boolean {
    return this.machineModelDetails['machineModelName']?.trim() != '' &&
      this.selectedPropositionType?.toLowerCase() == PropositionMasterEnum.Autonomous.toLowerCase();
  }

  isPropositionConcession(): boolean {
    return this.machineModelDetails['machineModelName']?.trim() != '' &&
      this.selectedPropositionType?.toLowerCase() == PropositionMasterEnum.Concession.toLowerCase();
  }

  disableModelCodeForConcession(): boolean {
    if (this.disableLockdownFieldsForConcessionsFT)
      return false;
    return this.isPropositionConcession();
  }

  disableModelCodeForConcessionMachineLevel(): boolean {
    if (this.screenType != 'edit') return true;    
    else return false;
  }

  edit() {
    this.screenType = 'edit';
    this.isDisabled = false;
    this.tabChange.next(-2);
  }

  toasterForError(err) {
    this.spinner.hide();
    let errors = err.error;
    errors.forEach((element) => {
      this.toaster.open({
        text: element.msg,
        type: 'danger',
        position: 'top-right',
        duration: 10000,
      });
    });
  }

  getMachineModelList() {
    let modelName = this.machineModelDetails['machineModelName'];
    this.machineService.getMachineModelList(modelName, this.selectedPropositionType).subscribe(
      (response) => {
        if(response && response['data'].length > 0) {
          this.machineModelList = response['data'];

          this.checkIfCurrentModelCodeStillExists();

          this.machineModelDetails['machineModelList'] = this.machineModelList;
        }
      },
      (err) => {
        if (err) {
          this.toasterForError(err);
        }
      }
    );
  }

  checkIfCurrentModelCodeStillExists() {
    const machineModel = this.machineModelList.find(f => f.machineModelId == this.machineModelDetails['machineModelId']);
    this.excludeDiscontinuedModelCode(machineModel);
  }

  excludeDiscontinuedModelCode(currentModelCode){
    if (this.isPropositionConcession()){
      this.machineModelList = this.machineModelList.filter(x => x.machineModelId == currentModelCode.machineModelId || !this.excludedMachineModelList.some(s => s == x.machineModelCode))
    }
  }

  getMachineModelDetails() {
    this.spinner.show();
    let machineModelId = '';
    let entityDetails = this.entityService.addEntityOnHoldData['level-8']['tab-0'];
    let existingModelDetails = this.entityService.addEntityOnHoldData['level-8']['tab-1'];
    if (this.screenType == 'add' && entityDetails != null && entityDetails != '' && (this.machineId == null || this.machineId == '')) {
      machineModelId = entityDetails['machineModelId'];
    }

    if (this.screenType == 'add' && this.entityService.saveMachineModelDetails && existingModelDetails != null && existingModelDetails != '' && Object.keys(existingModelDetails).length > 0) {
      this.spinner.hide();
      this.machineModelDetails = existingModelDetails;
      this.identifyPropositionType(); //GD2-3459

      if (this.selectedPropositionType?.toLowerCase().trim() == PropositionMasterEnum.Capsules.toLowerCase()) {
        //GD2-3459
        this.capsulesModelDetails.waterConnection = this.machineModelDetails['waterConnection'];
        this.capsulesModelDetails.milkType = this.machineModelDetails['milkType']; //[GD2-3787]
        this.entityService.entityExistingData['level-' + this.levelNumber]['tab-1'] = this.machineModelForm.value;
        this.entityService.entityLatestData['level-' + this.levelNumber]['tab-1'] = this.machineModelForm.value;
      } else {

        if (this.selectedPropositionType?.toLowerCase().trim() != PropositionMasterEnum.Capsules.toLowerCase() &&
          this.selectedPropositionType?.toLowerCase().trim() != PropositionMasterEnum.Autonomous.toLowerCase()) {
          this.machineModelForm.controls['machineModelCode'].setValue(this.machineModelDetails['machineModelId']);
        }

        if (this.isPropositionConcession()) {
          this.machineModelList = this.machineModelDetails['machineModelList'];
        }

        this.radioOptions.forEach((element) => {
          this.machineModelForm.patchValue({ [element['controlName']]: this.machineModelDetails[element['controlName']] });
          this.entityService.entityExistingData['level-' + this.levelNumber]['tab-1'] = this.machineModelForm.value;
          this.entityService.entityLatestData['level-' + this.levelNumber]['tab-1'] = this.machineModelForm.value;
          this.machineModelForm.valueChanges.subscribe((x) => {
            this.entityService.entityLatestData['level-' + this.levelNumber]['tab-1'] = this.machineModelForm.value;
          });
        });
      }

      this.valuesSetToForm = true;
      this.hasPageLoad = true;
      this.initialFormValues = this.machineModelForm.getRawValue()

    } else {
      this.machineService.getMachineModelDetails(this.machineId, machineModelId).subscribe(
        (response) => {
          this.spinner.hide();
          if (response) {
            this.machineModelDetails = response['data'];
            this.selectedPropositionType = this.machineModelDetails['propositionType']?.trim();
            if (this.machineId) {
              // GD2-3459
              this.identifyPropositionType();
            }

            if (this.selectedPropositionType?.toLowerCase() == PropositionMasterEnum.Capsules.toLowerCase() ||
              this.selectedPropositionType?.toLowerCase() == PropositionMasterEnum.Concession.toLowerCase()) {
              //GD2-3459
              this.createFormGroup();
            }

            let modelName = this.machineModelDetails['machineModelName'];
            if (this.selectedPropositionType.toLowerCase() == PropositionMasterEnum.Capsules.toLowerCase() &&
              (this.screenType == 'edit' || this.screenType == 'read')) {
              this.capsulesModelDetails.waterConnection = this.machineModelDetails['waterConnection']; // GD2-3459
              this.capsulesModelDetails.milkType = this.machineModelDetails['milkType']; //[GD2-3787]
            } else {
              if (
                this.selectedPropositionType?.toLowerCase().trim() != PropositionMasterEnum.Capsules.toLowerCase() &&
                this.selectedPropositionType?.toLowerCase().trim() != PropositionMasterEnum.Autonomous.toLowerCase())
                this.machineModelForm.controls['machineModelCode'].setValue(this.machineModelDetails['machineModelId']);

              if (!this.isPropositionConcession()) {
                this.radioOptions.forEach((element) => {
                  if (this.machineModelDetails['machineModelType']?.trim() == 'Proprietary') {
                    if (!element.values[modelName]) {
                      //add runtime element as now new machine models added from master configurations
                      element.values[modelName] = Object.values(element.values)[0];
                    }
                  }
                  this.machineModelForm.patchValue({ [element['controlName']]: this.machineModelDetails[element['controlName']] });

                  this.entityService.entityExistingData['level-' + this.levelNumber]['tab-1'] = this.machineModelForm.value;
                  this.entityService.entityLatestData['level-' + this.levelNumber]['tab-1'] = this.machineModelForm.value;
                  this.machineModelForm.valueChanges.subscribe((x) => {
                    this.entityService.entityLatestData['level-' + this.levelNumber]['tab-1'] = this.machineModelForm.value;
                  });
                });
              }
            }
          }
          this.hasPageLoad = true;
          this.initialFormValues = this.machineModelForm.getRawValue()

          if (this.isPropositionConcession())
            this.getMachineModelList();
        },
        (err) => {
          this.spinner.hide();
          this.machineModelDetails = {};
        },
      );
    }
  }

  createFormGroup() {
    this.machineModelForm = new FormGroup({});
    if (
      this.selectedPropositionType?.toLowerCase().trim() == PropositionMasterEnum.Capsules.toLowerCase() ||
      this.selectedPropositionType?.toLowerCase().trim() == PropositionMasterEnum.Autonomous.toLowerCase()
    ) {
      //GD2-3459
      let defaultValue = this.screenType == 'add' ? 'Information Not Available' : '';
      this.capsulesModelDetails.waterConnection = defaultValue;
      this.capsulesModelDetails.milkType = defaultValue; //[GD2-3787]
      this.radioOptions = []
    }
    else {
      this.machineModelForm.addControl('machineModelCode', new FormControl('', [Validators.required]));
      
      if(this.isPropositionConcession()) {
        this.radioOptions = [];
      }
      else {
        this.radioOptions.forEach((element) => {
          this.machineModelForm.addControl(element['controlName'], new FormControl('', [Validators.required]));
        });
      }
    }
  }

  submitForm(type) {
    if (type == 'cancel') {
      if (this.screenType == 'read' || (this.screenType == 'edit' && !this.hasUnsavedChanges())) {
        this.router.navigate(['/machine']);
      } else {
        this.openDialog('form_cancel');
      }
    } else {
      this.machineModelForm.markAllAsTouched();
      if (this.machineModelForm.status === 'INVALID' && this.selectedPropositionType?.toLowerCase().trim() != PropositionMasterEnum.Autonomous.toLowerCase()) {
        this.toaster.open({
          text: 'Please provide valid input for all the highlighted fields',
          type: 'danger',
          position: 'top-right',
          duration: 10000,
        });
      } else {
        let machineModelId = this.isPropositionConcession() ? this.machineModelForm.controls['machineModelCode'].value : this.machineModelDetails['machineModelId'];
        let body = {
          machineId: this.machineId,
          machineModelId: machineModelId,
        };
        if (this.selectedPropositionType?.toLowerCase().trim() == PropositionMasterEnum.Capsules.toLowerCase()) {
          //GD2-3459
          body['waterConnection'] = this.capsulesModelDetails.waterConnection;
          this.machineModelDetails['waterConnection'] = this.capsulesModelDetails.waterConnection;
          body['milkType'] = this.capsulesModelDetails.milkType; //[GD2-3787]
          this.machineModelDetails['milkType'] = this.capsulesModelDetails.milkType; //[GD2-3787]
        } else {
          this.radioOptions.forEach((element) => {
            body[element['controlName']] = this.machineModelForm.value[element['controlName']];
            this.machineModelDetails[element['controlName']] = this.machineModelForm.value[element['controlName']];
          });
        }

        if (this.screenType == 'add') {
          this.entityService.isValidatedMachineModelTab = true;
          this.entityService.addEntityOnHoldData['level-8']['tab-1'] = this.machineModelDetails;
          this.tabChange.next(2);
        } else {
          this.spinner.show();
          this.machineService.addMachineModelDetails(body).subscribe(
            (response) => {
              //this.spinner.hide();
              this.toaster.open({
                text: 'Model code updated successfully',
                type: 'success',
                position: 'top-right',
                duration: 10000,
              });
              this.resetChanges(true);
              this.getMachineModelDetails();
              this.tabChange.next(-1); // to get machine details
            },
            (err) => {
              if (err) {
                this.spinner.hide();
                let errors = err.error;
                errors.forEach((element) => {
                  this.toaster.open({
                    text: element.msg,
                    type: 'danger',
                    position: 'top-right',
                    duration: 10000,
                  });
                });
              }
            },
          );
        }
      }
    }
  }

  openDialog(type: string = '', element = {}, slot_i = -1): void {
    if (type == 'form_cancel') {
      this.dialogTexts = {
        successTitle: 'Yes',
        cancelTitle: 'No',
        message: 'All unsaved changes will be lost. Are you sure you want to cancel?',
        deviceName: '',
        status: '',
        title: 'Confirmation',
        type: type,
      };
    }
    const entityMoveDataModel = new MatDialogConfig();
    entityMoveDataModel.height = 'auto';
    entityMoveDataModel.width = '670px';
    entityMoveDataModel.disableClose = true;
    this.dialogRef = this.dialog.open(this.confirmDialogTemplate, entityMoveDataModel);
  }
  onConfirm(type: string = 'form_cancel') {
    this.resetChanges();
    this.dialogRef.close();
    this.tabChange.next(0);
  }
  onCancel() {
    this.dialogRef.close();
  }

  identifyPropositionType() {
    let entityDetails = this.entityService.addEntityOnHoldData['level-8']['tab-0'];
    if (this.screenType == 'add' && entityDetails != null && entityDetails != '' && (this.machineId == null || this.machineId == '')) {
      this.selectedPropositionType = entityDetails['selectedPropositionType'];
    }
    if (this.selectedPropositionType != '' && this.selectedPropositionType != undefined) {
      if (this.selectedPropositionType.toLowerCase() == PropositionMasterEnum.Capsules.toLowerCase() || this.selectedPropositionType.toLowerCase() == PropositionMasterEnum.Autonomous.toLowerCase()) {
        this.isDisabled = true;
        if (this.screenType == 'add' && !this.valuesSetToForm) {
          this.createFormGroup();
        }
      }
    }
  }

  hasUnsavedChanges() {
    const rawForm = this.machineModelForm.getRawValue()
    return Object.keys(this.initialFormValues).reduce((acc, key) => {
        if (this.initialFormValues[key] !== rawForm[key]) {
            return true
        }
        return acc
    }, false)
  }

  resetChanges(save?: boolean) {
    if (save) {
      this.initialFormValues = this.machineModelForm.getRawValue()
      return
    }
    this.machineModelForm.patchValue(this.initialFormValues)
  }

  protected readonly PageTypeEnum = PageTypeEnum;
}
