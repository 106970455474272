import { Component, OnInit, Output, TemplateRef, ViewChild, EventEmitter, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertConfirmDialogModel } from 'src/app/components/common/alert-confirm-dialog/alert-confirm.dialog.model';
import { CreateRemoteUpdateTaskRequest } from 'src/app/components/remote-update-tasks/dtos/create-remote-update-task.model';
import { GetPackageSelectList, PackageSelectItem } from 'src/app/components/remote-update-tasks/dtos/get-package-select-list.model';
import { RemoteUpdateTaskType } from 'src/app/components/remote-update-tasks/enums/remote-update-task-type.enum';
import { GetPackageService } from 'src/app/components/remote-update-tasks/services/package/get-package.service';

@Component({
  selector: 'app-task-package',
  templateUrl: './task-package.component.html',
  styleUrls: ['./../../new-task-step-common.css', './task-package.component.css']
})
export class TaskPackageComponent implements OnInit {
  @ViewChild("dialogValidationError") dialogValidationError: TemplateRef<any>;
  errorDialog: boolean = false;

  @Output() nextStepEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() backStepEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedPackageEvent: EventEmitter<PackageSelectItem> = new EventEmitter<PackageSelectItem>();

  @Input() newTaskRequest: CreateRemoteUpdateTaskRequest = new CreateRemoteUpdateTaskRequest();

  getPackageSelectList: GetPackageSelectList = { packages: [] };

  packageFormGroup: FormGroup = new FormGroup({
    package: new FormControl({ value: '', disabled: false }, Validators.required)
  });

  selectedTaskType: RemoteUpdateTaskType;
  selectedPropositionId: string = '';

  alertConfirmDialogModel: AlertConfirmDialogModel = null;
  dialogRef: MatDialogRef<any>;

  public showSnackBar: boolean = false;
  public snackBarHeader: string = '';
  public snackBarMessage: string = '';

  constructor(
    private packageService: GetPackageService,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.loadPackage();
  }

  isInvalid(field: string) {
    const control = this.packageFormGroup.get(field)
    return control.invalid && control.touched
  }

  nextStep() {
    this.packageFormGroup.controls.package.updateValueAndValidity();
    switch (this.packageFormGroup.status) {
      case "VALID":
        let packageItem: PackageSelectItem = this.packageFormGroup.get("package").value;
        this.newTaskRequest.Package = `${packageItem.packageName} - ${packageItem.packageVersion}`;
        this.newTaskRequest.PackageId = packageItem.packageId;
        this.nextStepEvent.emit();
        break;
      case "INVALID":
        this.openAlertDialog('Please complete all the required fields.', 'Selection Error');
        this.packageFormGroup.markAllAsTouched();

        this.errorDialog = true;
        break;
    }
  }

  backStep(): void {
    this.backStepEvent.emit();
  }

  public openErrorDialog() {
    this.errorDialog = true;
  }

  public closeErrorDialog() {
    this.errorDialog = false;
  }

  openAlertDialog(message: string, title: string) {
    this.alertConfirmDialogModel = new AlertConfirmDialogModel();
    this.alertConfirmDialogModel.action = "Information";
    this.alertConfirmDialogModel.dialogId = "task-parameer-validation-fix";
    this.alertConfirmDialogModel.okButtonName = "OK";
    this.alertConfirmDialogModel.isVisibleCancel = false;
    this.alertConfirmDialogModel.title = title;
    this.alertConfirmDialogModel.message = message;
  }

  loadPackage() {
    this.showSnackBar = false;
    if (this.isTaskRequestLoaded() && this.taskRequestHasChanges()) {
      this.selectedPropositionId = this.newTaskRequest.PropositionTypeId;
      this.selectedTaskType = this.newTaskRequest.TaskType;

      this.spinner.show();

      this.packageService.getRemoteUpdatePackageList(
        this.selectedTaskType,
        this.selectedPropositionId
      ).subscribe(
        data => {
          this.spinner.hide();
          this.getPackageSelectList = data;
          this.showSnackBar = false;

          this.showNoPackagesMessage();
        },
        err => {
          this.showSnackBar = true;
          this.snackBarMessage = "Something went wrong with your request, try again in a few minutes";
          this.spinner.hide();
        }
      );
    } else {
      this.showNoPackagesMessage();
    }
  }

  selectedPackage(event: any): void {
    this.selectedPackageEvent.emit(event.value);
  }

  closeSnackBar() {
    this.showSnackBar = false;
  }

  private showNoPackagesMessage() {
    if (this.getPackageSelectList.packages.length == 0) {
      this.showSnackBar = true;
      this.snackBarMessage = "There are no packages available for selection.";
    }
  }

  private isTaskRequestLoaded() {
    return !!this.newTaskRequest?.PropositionTypeId ||
      !!this.newTaskRequest?.TaskType;
  }

  private taskRequestHasChanges() {
    return this.selectedPropositionId != this.newTaskRequest.PropositionTypeId
      || this.selectedTaskType != this.newTaskRequest.TaskType;
  }
}
