import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpResponse } from '@angular/common/http';
import { DialogService } from 'src/app/components/dialog/dialog.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-export-excel-button',
  templateUrl: './export-excel-button.component.html',
  styleUrls: ['./export-excel-button.component.css']
})
export class ExportExcelButtonComponent implements OnInit {

  @Input() handleExportFile: () => Observable<any>;

  public isDisabledExportButton: boolean = false;

  constructor(
    private spinner: NgxSpinnerService,
    private dialogService: DialogService,
  ) { }

  ngOnInit(): void {
  }

  private handleExcelDownload(response: HttpResponse<Blob>) {
    const contentDispositionHeader = response.headers.get('content-disposition');
    const fileName = contentDispositionHeader
      ? contentDispositionHeader.split('filename=')[1].trim()
      : 'download.xlsx';

    const blob = new Blob([response.body], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.style.display = 'none';

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(link.href);
  }

  public exportToExcel(): void {
    this.spinner.show();
    this.isDisabledExportButton = true;
    this.handleExportFile().subscribe(blob => {
      this.handleExcelDownload(blob);
    },
    () => {
      this.dialogService.showErrorDialog();
      this.isDisabledExportButton = false;
    },
    () => {
      this.spinner.hide();
      this.isDisabledExportButton = false;
    }
    );
  }
}
