import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { TableMachineSelectionComponent } from './components/table-machine-selection/table-machine-selection.component';
import { AlertConfirmDialogModel } from 'src/app/components/common/alert-confirm-dialog/alert-confirm.dialog.model';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CreateRemoteUpdateTaskRequest } from 'src/app/components/remote-update-tasks/dtos/create-remote-update-task.model';
import { PackageSelectItem } from 'src/app/components/remote-update-tasks/dtos/get-package-select-list.model';
import { RemoteUpdateTaskType } from 'src/app/components/remote-update-tasks/enums/remote-update-task-type.enum';

@Component({
  selector: 'app-step-machine-selection',
  templateUrl: './step-machine-selection.component.html',
  styleUrls: ['./step-machine-selection.component.css', './../new-task-step-common.css']
})
export class StepMachineSelectionComponent implements OnInit {
  @ViewChild(TableMachineSelectionComponent, { static: false }) tableMachineSelectionComponent: TableMachineSelectionComponent;
  errorDialog: boolean = false;

  @ViewChild("dialogValidationError") dialogValidationError: TemplateRef<any>;
  @Output() nextStepEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() backStepEvent: EventEmitter<any> = new EventEmitter<any>();
  @Input() newTaskRequest : CreateRemoteUpdateTaskRequest = new CreateRemoteUpdateTaskRequest();
  @Input() package : PackageSelectItem = {} as PackageSelectItem;
  alertConfirmDialogModel: AlertConfirmDialogModel = null;
  dialogRef: MatDialogRef<any>;

  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {}

  isCertificateManagement = false

  public loadMachine() {
    this.isCertificateManagement = this.newTaskRequest.TaskType === RemoteUpdateTaskType.CertificateManagement
    setTimeout(() => {
      this.tableMachineSelectionComponent.clearMachineCheckeds();
      this.tableMachineSelectionComponent.fetchMachineDataAPI(
        this.newTaskRequest,
        this.package
      );
    },10)
  }

  nextStep() {
    if (this.tableMachineSelectionComponent.hasMachineSelected()) {
      const machinesSelected = this.tableMachineSelectionComponent.getMachinesSelected();
      this.newTaskRequest.Machines = Array.from(machinesSelected);
      this.nextStepEvent.emit();
    }
    else {
      this.openAlertDialog('Error with machine selection, please select at least one machine.', 'Selection Error');
    }
  }

  backStep() {
    this.backStepEvent.emit();
  }

  openAlertDialog(message: string, title: string) {
    this.alertConfirmDialogModel = new AlertConfirmDialogModel();
    this.alertConfirmDialogModel.action = "Information";
    this.alertConfirmDialogModel.dialogId = "task-machine-selection-validation-fix";
    this.alertConfirmDialogModel.okButtonName = "OK";
    this.alertConfirmDialogModel.isVisibleCancel = false;
    this.alertConfirmDialogModel.title = title;
    this.alertConfirmDialogModel.message = message;

    this.errorDialog = true;
  }

  public openErrorDialog() {
    this.errorDialog= true;
  }

  public closeErrorDialog() {
    this.errorDialog= false;
  }
}
