import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Toaster } from "ngx-toast-notifications";
import { Observable } from 'rxjs-compat';
import { map, startWith } from 'rxjs/operators';
import { EntityService } from 'src/app/services/entity/entity.service';
import { MachineService } from 'src/app/services/machine/machine.service';
import { RoleService } from 'src/app/services/role/role.service';
import { AlertConfirmDialogModel } from '../../common/alert-confirm-dialog/alert-confirm.dialog.model';
import { MachineMoveDataModel } from '../machine-move/machine-move.model';
import { MachineMoveModel } from './machine-list.model';
import { AzureService } from 'src/app/services/azure/azure.service';

declare var $: any;
@Component({
  selector: 'app-machine-list',
  templateUrl: './machine-list.component.html',
  styleUrls: ['./machine-list.component.css']
})

export class MachineListComponent implements OnInit {
  globalChangesFT: boolean;
  enableGridImprovementsFT: boolean;
  showEntityOrRecordName: string = 'Entity';
  module: string = "Machine";
  machineMoveDataModel: MachineMoveDataModel = new MachineMoveDataModel();
  displayedColumns: string[];
  dataSource: any = [];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('paginator') paginator: MatPaginator;
  isShowFilter = false;
  drawerfilter: boolean = false;
  isMoveShow:boolean = false;
  selectedMachine: any;
  isFilterClear : boolean = false;
  isFilterApply : boolean = false;
  machineList: any = []
  markets: any = [];
  marketsForAssignment: any = [];//GD2-3874
  partners: any = [];
  sites: any = [];
  machineTypes: any = [];
  machineStatus: any = [];
  connectivityStatuses: any[];
  connectivityStatusHtmlClass = {
    "Connected": "connected",
    "Disconnected": "disconnected",
    "Not Connected": "not-connected" 
  };
  workingStatus: any[];
  commissionningStatus: any[];
  targetWorkingState: any[];
  suspendReason: any[];
  filter = {
    propositionTypeId: [], //GD2-4430
    marketId: '',
    partnerIds: [],
    siteIds: [],
    machineType: '',
    machineStatus: '',
    connectivityStatus: '',
    workingStatus: '',
    commissionningStatus: '',
    search: '',
    targetWorkingState: '',
    suspendReason: ''
    }
    tempfilter = {//[GD2-3706]
        propositionTypeId: [], //GD2-4430
        marketId: '',
        partnerIds: [],
        siteIds: [],
        machineType: '',
        machineStatus: '',
        connectivityStatus: '',
        workingStatus: '',
        commissionningStatus: '',
        search: '',
        targetWorkingState: '',
        suspendReason: ''
    }
  filterColumns: string[] = ['propositionTypeId', 'marketId', 'partnerIds', 'siteIds', 'machineType', 'machineStatus', 'connectivityStatus', 'workingStatus', 'commissionningStatus', 'targetWorkingState', 'suspendReason'];//[GD2-3706]
  recordsTotal = 0;
  pageIndex = 0;
  pageSizeOptions: number[] = [10, 25, 50, 100];
  params = {
    pageNumber: 1,
    pageSize: this.pageSizeOptions[0],
    searchText: '',
    sortBy: 'machineSystemId desc',
    sotyByunrecognised : 'machineSrNo desc'
  };
  isPageDataLoad = false;
  textLabel : string = 'Machine List';
  unrecognisedmachineList : any[] = [];
  unrecognisedMachineListColumns: string[] = ['machineSrNo', 'machineTypeCode', 'scharerMachineCode'];
  isOpenAssignToMarketPopup : boolean = false;
  machineDetails : any = {};
  assignTo = {
    marketId: ''
  }
  assignMarketList: Observable<string[]>;
  assignToMarketFormGroup: FormGroup;
  siteIdFromEntity = [];
  dialogRef: MatDialogRef<any>;
  moveMachineId = '';
  moveUniqueMachineNumber = '';//[GD2-3239]
  advanceFilter = {
    entityID: false,
    entityName: false,
    billTo: false,
    sellTo: false,
    reference: false,
    machineSerialNumber: false
  }
  displayAdvancedSearch = false;

  filterMachinesByPreviousEntity:any = null;
  moveToSalesDiscrepancySceenOnMove = false;
  @ViewChild("infoAlertTemplate") infoAlertTemplate: TemplateRef<any>;
  alertConfirmDataModel: AlertConfirmDialogModel = new AlertConfirmDialogModel();

  propositionTypes: any = [];

    constructor(
    private spinner: NgxSpinnerService,
    private machineService: MachineService,
    private activatedRoute: ActivatedRoute, 
    private router: Router,
    private toaster: Toaster,
    public roleService:RoleService,
    private dialog: MatDialog,
    private entityService: EntityService,
    private azureService: AzureService
  ) { }
  
  async ngOnInit() {   
    await this.SetFeatureFlagsConfigurations();

    this.filterMachinesByPreviousEntity=null;
    if(this.roleService.objRole.isUserAuditorGRID || this.roleService.objRole.isUserAuditorLevel1 || this.roleService.objRole.isUserAuditorLevel2 || this.roleService.objRole.isUserAuditorLevel3) {
      this.router.navigate(['/unauthorized']);
    }
    if(localStorage.getItem("filterMachinesBySite")) {      
        this.filter.siteIds = JSON.parse(localStorage.getItem("filterMachinesBySite"));
        this.siteIdFromEntity = JSON.parse(localStorage.getItem("filterMachinesBySite"));
        localStorage.removeItem("filterMachinesBySite")
        this.assignFilterToTempFilter();
    }
    else if(localStorage.getItem("filterMachinesByPreviousEntity")) {
      this.filterMachinesByPreviousEntity = JSON.parse(localStorage.getItem("filterMachinesByPreviousEntity"));
      localStorage.removeItem("filterMachinesByPreviousEntity")
    }
    
    localStorage.removeItem("selectedMachineModel");
    localStorage.removeItem("moveMachineModel");
    this.assignToMarketFormGroup = new FormGroup({});
    let validations = [];
    validations.push(Validators.required);
    validations.push(this.requireMatch('marketId',this));
    this.assignToMarketFormGroup.addControl('marketId', new FormControl('', validations));
    
    if (this.roleService.objRole.isSystemAdmin || this.roleService.objRole.isGlobalMarketAdmin || this.roleService.objRole.isGridR || this.roleService.objRole.isUserAuditorGRIDR) {
      this.getPropositionTypes(); //GD2-4430
    }    
    if (this.roleService.objRole.isSystemAdmin || this.roleService.objRole.isGlobalMarketAdmin || this.roleService.objRole.isGridR || this.roleService.objRole.isMarketAdmin || this.roleService.objRole.isMarketR || this.roleService.objRole.isUserAuditorGRIDR || this.roleService.objRole.isUserAuditorLevel1R){
      this.getMarketLookup();
    }
    if(!(this.roleService.objRole.isSiteSupportL1 || this.roleService.objRole.isSiteSupportL2 || this.roleService.objRole.isSiteSupportL3)){
      this.unrecognisedMachineListColumns.push('actions')
    }
    this.getPartnerLookup(true);
    //this.getSiteLookup(true);
    this.getCommissionningStatusLookup();
    this.getWorkingStatusLookup();
    this.getMachineStatusLookup();
    this.getConnectivityStatusLookup();
    //this.getMachineTypeLookup(true);
    this.getSuspendStateLookup();
    this.getTargetWorkingStateLookup();
    if(this.filterMachinesByPreviousEntity){
      this.getEntityFiltersByPreviousEntity();
    }
    else{
      this.getMachineList();
    }
   }
    applyFilter() {//[GD2-3706]
        this.assignFilterToTempFilter();
        this.getMachineList('apply');
    }

    assignFilterToTempFilter() {//[GD2-3706]
        this.filterColumns.forEach(key => {
            this.tempfilter[key] = this.filter[key];
        });
    }
    updatePropositionTypesId ($event: {id: string}[]) {
      this.filter.propositionTypeId = $event.map(({id}) => id)
      this.getMarketLookup(true)
    }
    updatePartnerIds ($event: {id: string}[]) {
      this.filter.partnerIds = $event.map(({id}) => id)
      this.getSiteLookup(false)
    }
    updateSiteIds ($event: {id: string}[]) {
      this.filter.siteIds = $event.map(({id}) => id)
    }
  changefilter() {
    this.roleService.setPopupScroll('section-machine-filter');  
    if (!this.drawerfilter) {
      this.displayAdvancedSearch = false;
      $(".sidebar-mini").addClass("filter-open");
   }
    else {
        this.filterColumns.forEach(key => {//[GD2-3706]
            let valueChanged = false;
            if (this.filter[key] != this.tempfilter[key]) { valueChanged = true; }
            this.filter[key] = this.tempfilter[key];
            if (key == 'propositionTypeId' && this.filter['propositionTypeId'].length >= 0 && valueChanged) {
              this.getMarketLookup(true);
            }
            if (key == 'marketId' && this.filter['marketId'].length >= 0 && valueChanged) {
                this.getPartnerLookup(false);
            }
            if (key == 'partnerIds' && this.filter['partnerIds'].length >= 0 && valueChanged) {
                this.getSiteLookup(false);
            }
        });
       $(".sidebar-mini").removeClass("filter-open");
       this.roleService.resetPopupScroll()
   }
    setTimeout(() => {
      this.drawerfilter = !this.drawerfilter;  
      
    }, this.roleService.scrollSetTimeoutTime); 
  }

  changefilter1() {
    this.roleService.setPopupScroll('section-machine-filter');      
      setTimeout(() => {       
      
    this.drawerfilter = !this.drawerfilter;
    if(this.drawerfilter){
      $(".sidebar-mini").addClass("filter-open")
    }
    else{
      $(".sidebar-mini").removeClass("filter-open")
    }
  }, this.roleService.scrollSetTimeoutTime);

  }
  clickFilter(val) {
    this.isShowFilter = val;

  }
  clearFilter() {
    this.filter.marketId = '';
    this.filter.siteIds = [];
    this.filter.partnerIds = [];
    this.filter.commissionningStatus = '';
    this.filter.machineStatus = '';
    this.filter.machineType = '';
    this.filter.workingStatus = '';
    this.filter.connectivityStatus = '';
    this.filter.targetWorkingState = '';
    this.filter.suspendReason = '';
    this.filter.propositionTypeId = [];
    this.isFilterClear = true;
    this.isFilterApply = false;
    let fromClear = true;
    if (this.roleService.objRole.isSystemAdmin || this.roleService.objRole.isGlobalMarketAdmin || this.roleService.objRole.isGridR || this.roleService.objRole.isUserAuditorGRIDR) {
      this.getMarketLookup();
    }
    this.assignFilterToTempFilter();//[GD2-3706]
    this.getPartnerLookup(true);   
    this.getMachineList(true)
  }
  clearSearch(){
    this.filter.search = '';
    this.advanceFilter = {
      entityID: false,
      entityName: false,
      billTo: false,
      sellTo: false,
      reference: false,
      machineSerialNumber: false
    }
    if(this.textLabel == 'Machine List'){
      this.getMachineList()
    } else if(this.textLabel == 'Unrecognised Machines'){
      this.getUnrecognisedMachineList();
    }
  }
  sortData(e) {
    this.params.pageNumber = 1;
    if(this.textLabel == 'Machine List'){
      this.params.sortBy = (e.active != '' && e.direction != '' ? e.active + ' ' + e.direction : (e.active != '' && e.direction == '') ? e.active + ' asc' : 'cells_name asc');
      this.getMachineList()
    } else if(this.textLabel == 'Unrecognised Machines'){
      this.params.sotyByunrecognised = (e.active != '' && e.direction != '' ? e.active + ' ' + e.direction : (e.active != '' && e.direction == '') ? e.active + ' asc' : 'cells_name asc');
      this.getUnrecognisedMachineList();
    }
  }
  pageChange(e) {
    this.params.pageNumber = (e.pageIndex > 0 ? (e.pageIndex + 1) : 1);
    this.params.pageSize = (e.pageSize > 0 ? e.pageSize : this.pageSizeOptions[0]);
    if(this.textLabel == 'Machine List'){
      this.getMachineList()
    } else if(this.textLabel == 'Unrecognised Machines'){
      this.getUnrecognisedMachineList();
    }
  }
  getMachineList(fromClear = undefined) {    
    this.displayAdvancedSearch = false;
    this.spinner.show();
    if(!this.isFilterClear){
    this.isShowFilter = false;
    //this.drawerfilter = false;
    }
    this.isFilterClear = false;
    if(fromClear == "apply"){
      //$(".sidebar-mini").removeClass("filter-open")
      this.changefilter();
    }
    if(fromClear == "search" || fromClear == "apply" || fromClear == "tabChange"){
      this.params.pageNumber = 1;
    }
    if(fromClear == undefined)
      this.drawerfilter = false;
    let obj = {};
    this.isFilterApply = false;
    if (this.filter.marketId) { obj['marketId'] = this.filter.marketId ;this.isFilterApply = true;};
    if (this.filter.partnerIds && this.filter.partnerIds.length > 0) { 
      obj['partnerIds'] = this.filter.partnerIds;
      this.isFilterApply = true; 
    };
    if (this.filter.siteIds && this.filter.siteIds.length > 0) { 
      obj['siteIds'] = this.filter.siteIds;
      this.isFilterApply = true; 
    };
    if (this.filter.commissionningStatus != '') { obj['commissioningStatusMasterId'] = this.filter.commissionningStatus;this.isFilterApply = true; };
    if (this.filter.targetWorkingState != '') { obj['targetWorkingStatusMasterId'] = this.filter.targetWorkingState;this.isFilterApply = true; };
    if (this.filter.suspendReason != '') { obj['suspendedReasonMasterId'] = this.filter.suspendReason;this.isFilterApply = true; };
    if (this.filter.machineStatus != '') { obj['machineStatusMasterId'] = this.filter.machineStatus;this.isFilterApply = true; };
    if (this.filter.connectivityStatus != '') { obj['connectivityStatusMasterId'] = this.filter.connectivityStatus;this.isFilterApply = true; };
    if (this.filter.machineType != '') { obj['machineTemplateId'] = this.filter.machineType;this.isFilterApply = true; };
    if (this.filter.workingStatus != '') { obj['workingStatusMasterId'] = this.filter.workingStatus;this.isFilterApply = true; };
    if (this.filter.search != '') { obj['searchText'] = this.filter.search; };
    if (this.params.pageNumber != undefined) { obj['pageNo'] = this.params.pageNumber };
    if (this.params.pageSize != undefined) { obj['pageSize'] = this.params.pageSize };
    if (this.params.sortBy != undefined) { obj['orderByColumn'] = this.params.sortBy };
    var advanceSearchColumns = [];
    if (this.advanceFilter.entityID) {advanceSearchColumns.push("EntityID")};
    if (this.advanceFilter.entityName) {advanceSearchColumns.push("EntityName")};
    if (this.advanceFilter.billTo) {advanceSearchColumns.push("BillTo")};
    if (this.advanceFilter.sellTo) {advanceSearchColumns.push("SellTo")};
    if (this.advanceFilter.reference) {advanceSearchColumns.push("Reference")};
    if (this.advanceFilter.machineSerialNumber) {advanceSearchColumns.push("MachineSerialNumber")};    
    if (advanceSearchColumns.length > 0) { obj['advanceSearch'] = advanceSearchColumns; }
    if (this.filter.propositionTypeId.length > 0) { obj['propositionTypeIds'] = this.filter.propositionTypeId.join(","); this.isFilterApply = true; } //GD2-5435

    this.machineService.getMachineList(obj).subscribe(response => {
      this.recordsTotal = response?.params?.count;      
      this.isPageDataLoad = true;
      if (response && response['data'].length) {
        this.machineList = response['data']
      } else {
        this.machineList = [];
      }
      this.spinner.hide();
    },err => {
      
      this.spinner.hide();
      let errors = err.errors == undefined ? err.error : err.errors;
      if(Array.isArray(errors)){
      errors.forEach(element => {
        this.toaster.open({
          text: element.msg,
          type: 'danger',
          position: 'top-right',
          duration: 10000
        });
      });
    }
    });
  }
  getEntityFiltersByPreviousEntity() {    
    this.spinner.show();
    this.entityService.getEntityFiltersByPreviousEntity(this.filterMachinesByPreviousEntity).subscribe(response => {           
      if (response && response['data']) {                
        if(response['data'].filterCustomers){
          this.filter.partnerIds = response['data'].filterCustomers.map(elem => elem.value)
          if(this.filter.partnerIds.length > 0){
            this.getSiteLookup(false);
          }
          else{           
            // if(this.filterMachinesByPreviousEntity.levelNumber <= 4) {
            //   this.partners = [];
            //   this.sites = [];
            // }                        
          }
        }
        if(response['data'].filterSites){
          this.filter.siteIds = response['data'].filterSites.map(elem => elem.value);
          // if(this.filter.siteIds.length == 0) {            
          //   this.sites = [];
          // }
        }
        if(this.filter.partnerIds.length == 0 && this.filter.siteIds.length == 0){
          this.isPageDataLoad = true;
          this.machineList = [];
          this.spinner.hide();
        }
        else {
          this.assignFilterToTempFilter();
          this.getMachineList();
        }
      } else {
        this.machineList = [];
        this.spinner.hide();
      }
      //this.spinner.hide();
    },err => {
      
      this.spinner.hide();
      let errors = err.errors == undefined ? err.error : err.errors;
      if(Array.isArray(errors)){
      errors.forEach(element => {
        this.toaster.open({
          text: element.msg,
          type: 'danger',
          position: 'top-right',
          duration: 10000
        });
      });
    }
    });
  }

  //GD2-3874
  getMarketLookupForAssignMarket(machineId = '', propositionTypeId = '') {
    this.machineService.getMarketLookup(machineId, propositionTypeId, 'true').subscribe(response => {
      this.marketsForAssignment = [];
      
      if (response && response['data'].length) {
        this.marketsForAssignment = response['data'];
        this.assignMarketList = this.assignToMarketFormGroup.controls['marketId'].valueChanges
        .pipe(
        startWith(''),
        map(value => this._filter(value))
        );
      }
     });
  }

  formatSelectOption(response: {text?: string, machineModelName?: string, masterDataId?: string, machineModelId?: string}[], allowAll = true) {
    return [...(allowAll ? [{name: 'All', id: ''}] : []), ...response.map((elem) => ({name: elem.text || elem.machineModelName, id: elem.masterDataId || elem.machineModelId}))]
  }

  getMarketLookup(fromPropositionTypeFilter = false) {
     //GD2-4430 start    
    let propositionTypeIds = '';
    if (this.filter.propositionTypeId.length > 0)
      propositionTypeIds = this.filter.propositionTypeId.join(",");
    //GD2-4430 end
    this.machineService.getMarketLookup('', propositionTypeIds, 'false').subscribe(response => {
      this.markets = [];
      if (response && response['data'].length) {
        this.markets = this.formatSelectOption(response.data);
        this.assignMarketList = this.assignToMarketFormGroup.controls['marketId'].valueChanges
        .pipe(
        startWith(''),
        map(value => this._filter(value))
        );
      } 

      if (this.filter.marketId != "" && fromPropositionTypeFilter) {
        this.filter.marketId = '';
        this.filter.partnerIds = [];
        this.filter.siteIds = [];
        this.getPartnerLookup(false);
      }
      else if (fromPropositionTypeFilter && this.markets.length == 0){
        this.partners = [];
        this.sites = [];
      }
      else if (fromPropositionTypeFilter && this.markets.length > 0 && this.partners.length == 0 && this.sites.length == 0) {
        this.getPartnerLookup(false);
      }
    });
  }
  getPartnerLookup(isPageLoad) {
    this.sites = [];
    this.partners = [];
    this.filter.siteIds = [];
    this.filter.partnerIds = [];
    if(!isPageLoad){
    this.spinner.show();
    }
  if (!(this.roleService.objRole.isLevel5R || this.roleService.objRole.isLevel6R || this.roleService.objRole.isSiteR)) {
    this.machineService.getPartnerLookup(this.filter.marketId).subscribe(response => {
      if(!isPageLoad){
      this.spinner.hide();
      }
      if (response && response['data'].length) {
        this.partners = this.formatSelectOption(response['data']);
      }
    });
   }
    if(this.filter.marketId == ""){
      this.getSiteLookup(isPageLoad);
    }
    
    this.getMachineTypeLookup(isPageLoad,'',this.filter.marketId);
    
  }
  getSiteLookup(isPageLoad) {
    this.sites = [];
    this.filter.siteIds = [];
    if(!isPageLoad){
    this.spinner.show();
    }else if(this.siteIdFromEntity && this.siteIdFromEntity.length > 0){ // GD2-1443 machine list filter by site  from entity list
      this.filter.siteIds = this.siteIdFromEntity;
      this.siteIdFromEntity = [];
    }
      if (this.filter.marketId != "" && this.filter.partnerIds.length == 0) { this.sites = []; this.spinner.hide(); }//[GD2-3796]
      else {
          this.machineService.getSiteLookupByParents(this.filter.partnerIds).subscribe(response => {
              if (!isPageLoad) {
                  this.spinner.hide();
              }
              if (response && response['data'].length) {
                  this.sites = this.formatSelectOption(response['data'], false)
              }
              else {
                  this.sites = [];
              }
          });
      }
  }
  getMachineTypeLookup(isPageLoad = true,propositionTypeId = '', selectedMarketId = '') {
    this.machineTypes = [];
    this.filter.machineType = '';
    if(!isPageLoad){
      this.spinner.show();
    }
    this.machineService.getMachineTypeLookup(propositionTypeId,selectedMarketId).subscribe(response => {
      if(!isPageLoad){
      this.spinner.hide();
      }
      if (response && response['data'].length) {
        this.machineTypes = this.formatSelectOption(response['data']);
      }
    });
  }
  getMachineStatusLookup() {
    this.machineStatus = [];
    this.filter.machineStatus = '';
    //this.spinner.show();
    this.machineService.getMachineStatusLookup().subscribe(response => {
      //this.spinner.hide();
      if (response && response['data'].length) {
        this.machineStatus = this.formatSelectOption(response['data']);
      }
    });
  }
  getConnectivityStatusLookup() {
    this.connectivityStatuses = [];
    this.filter.connectivityStatus = '';
    this.machineService.getConnectivityStatusLookup().subscribe(response => {
      if (response && response['data'].length) {
        this.connectivityStatuses = this.formatSelectOption(response['data']);
      }
    });
  }

  getWorkingStatusLookup() {
    this.workingStatus = [];
    this.filter.workingStatus = '';
    //this.spinner.show();
    this.machineService.getWorkingStatusLookup().subscribe(response => {
      //this.spinner.hide();
      if (response && response['data'].length) {
        this.workingStatus = this.formatSelectOption(response['data']);
      }
    });
  }
  getCommissionningStatusLookup() {
    this.commissionningStatus = [];
    this.filter.commissionningStatus = '';
    //this.spinner.show();
    this.machineService.getCommissionningStatusLookup().subscribe(response => {
      //this.spinner.hide();
      if (response && response['data'].length) {
        this.commissionningStatus = this.formatSelectOption(response['data']);
      }
    });
  }
  clickToAdd() {
    this.router.navigate(['/machine/add'], { queryParams: { tabIndex: 0, nextLevelNumber: 8, parentlevelNumber: 7 } });
  }
  openMachineMoveDialog(element) {
    if (element.hasAnySalesDiscrepancy || element.hasAnyDataHoldForCapsules) {
      // GD2-2201
      const alertConfirmDataModel = new MatDialogConfig();
        alertConfirmDataModel.height = 'auto';
        alertConfirmDataModel.width = '670px';
        alertConfirmDataModel.disableClose = true;
        this.alertConfirmDataModel = new AlertConfirmDialogModel();
        this.alertConfirmDataModel.action = "Information";
        this.alertConfirmDataModel.dialogId = "machine-sales-discrepancy";
        this.alertConfirmDataModel.okButtonName = "OK";
        this.alertConfirmDataModel.cancelButtonName = "Cancel";
        this.alertConfirmDataModel.isVisibleCancel = element.hasAnySalesDiscrepancy;
        this.alertConfirmDataModel.title = "Confirmation";
        this.alertConfirmDataModel.message = element.hasAnySalesDiscrepancy 
                                            ? 'You cannot move this machine until the outstanding sales data discrepancies are resolved' 
                                            : 'You cannot move this machine due to an outstanding capsule barcode data discrepancy'; //GD2-3876
        this.moveMachineId = element.machineId;
        this.moveUniqueMachineNumber = element.uniqueMachineNumber;//[GD2-3239]
        this.moveToSalesDiscrepancySceenOnMove = element.hasAnySalesDiscrepancy;
        this.dialogRef = this.dialog.open(this.infoAlertTemplate, alertConfirmDataModel);
    }
    else {
    localStorage.removeItem("selectedMachineModel");
    localStorage.removeItem("moveMachineModel");
    let machineMoveModel: MachineMoveModel = new MachineMoveModel();
    machineMoveModel.machineId = element.machineId;
    machineMoveModel.marketId = element.marketId;
    machineMoveModel.siteEntityId = element.siteEntityId;
    machineMoveModel.levelNumber = element.levelNumber;
    localStorage.setItem("selectedMachineModel", JSON.stringify(machineMoveModel));
    this.selectedMachine = element;
    const entityMoveDataModel = new MatDialogConfig();
    entityMoveDataModel.height = 'auto';
    entityMoveDataModel.width = '670px';
    entityMoveDataModel.disableClose = true;
    this.machineMoveDataModel = new MachineMoveDataModel();
    this.machineMoveDataModel.action = "Move";
    this.machineMoveDataModel.cancelButtonName = "Clear";
    this.machineMoveDataModel.requestBody = element;
    this.machineMoveDataModel.dialogId = "machine-move";
    this.machineMoveDataModel.okButtonName = "Move";
    this.machineMoveDataModel.title = this.module;
    
    this.roleService.setPopupScroll('main-machine-move');
    $(".sidebar-mini").addClass("filter-open")
      setTimeout(() => {
        this.isMoveShow = !this.isMoveShow;        
      }, this.roleService.scrollSetTimeoutTime);
    }
  }
  onMoveConfirm(data) {
    let machineId = data.machineId;
    let machineMoveModel:MachineMoveModel = new MachineMoveModel();
    machineMoveModel.machineId = data.machineId;
    machineMoveModel.nextLevelNumber = data.nextLevelNumber;
    machineMoveModel.parentEntityId = data.parentEntityId;
    machineMoveModel.parentLevelNumber =data.parentLevelNumber;
    machineMoveModel.marketId =data.marketId;
    machineMoveModel.siteEntityId = data.siteEntityId;
    machineMoveModel.movedToCrossMarket = data.movedToCrossMarket;
    
    this.roleService.setPopupScroll('main-machine-move');
    $(".sidebar-mini").removeClass("filter-open")
    this.roleService.resetPopupScroll()
      setTimeout(() => {
        this.isMoveShow = false;    
      }, this.roleService.scrollSetTimeoutTime);
    
    localStorage.setItem("moveMachineModel",JSON.stringify(machineMoveModel));
    this.router.navigate(['/machine/',machineId],{ queryParams:{ nextLevelNumber : data.nextLevelNumber,marketId : data.marketId,parentEntityId : data.parentEntityId,screenType:'move',parentlevelNumber:data.parentlevelNumber,isMachineMove:true,ismachineMovedToCrossMarket:data.movedToCrossMarket}})
  }
  onMoveCancel() {
    this.roleService.setPopupScroll('main-machine-move');
    $(".sidebar-mini").removeClass("filter-open")
    this.roleService.resetPopupScroll()
      setTimeout(() => {
        this.isMoveShow = false;    
      }, this.roleService.scrollSetTimeoutTime);
    //this.isMoveShow = false;
  }

  onTabChanged(event){
    this.textLabel = event.tab.textLabel;
    this.recordsTotal = 0;
    this.params.pageNumber = 1;
    this.params.pageSize = this.pageSizeOptions[0];
    this.filter.search = '';
    this.filter.marketId = '';
    this.filter.siteIds = [];
    this.filter.partnerIds = [];
    this.filter.commissionningStatus = '';
    this.filter.machineStatus = '';
    this.filter.machineType = '';
    this.filter.workingStatus = '';
    this.filter.targetWorkingState = '';
    this.filter.suspendReason = '';
    this.isFilterClear = true;
    this.isFilterApply = false;
    this.advanceFilter = {
          entityID: false,
          entityName: false,
          billTo: false,
          sellTo: false,
          reference: false,
          machineSerialNumber: false
      }
    this.getPartnerLookup(true);  
    if (this.textLabel == 'Machine List') {
      this.getMachineList('tabChange');
    } else if(this.textLabel == 'Unrecognised Machines'){
      this.getUnrecognisedMachineList('tabChange');
    }
  }
  onConfirm() {
    this.dialogRef.close();
    if (this.moveToSalesDiscrepancySceenOnMove) {
      localStorage.setItem("filterDiscrepancyByMachineId", this.moveMachineId);
      localStorage.setItem("filterDiscrepancyByMachineUniqueId", this.moveUniqueMachineNumber);//[GD2-3239]
      this.router.navigate(['/sales'], {queryParams: { tabIndex: 1 }});
    }
    this.moveToSalesDiscrepancySceenOnMove = false;
  }

  onCancel(){
    this.dialogRef.close();
  }

  getUnrecognisedMachineList(fromClear = ""){
    if(fromClear == "search" || fromClear == "apply" || fromClear == "tabChange"){
      this.params.pageNumber = 1;
    }
    let obj = {};
    if (this.filter.search != '') { obj['searchText'] = this.filter.search; };
    if (this.params.pageNumber != undefined) { obj['pageNo'] = this.params.pageNumber };
    if (this.params.pageSize != undefined) { obj['pageSize'] = this.params.pageSize };
    if (this.params.sortBy != undefined) { obj['orderByColumn'] = this.params.sotyByunrecognised };
    this.machineService.getUnrecognisedMachineList(obj).subscribe(response => {
      this.recordsTotal = response?.params?.count;
      this.isPageDataLoad = true;
      if (response && response['data'].length) {
        let responseData = response['data'];
        if(!this.globalChangesFT){
          this.unrecognisedmachineList = responseData.map((t) => { 
            return { 
              ...t, 
            }; 
          });
        } else {
          this.unrecognisedmachineList = responseData;
        }
      } else {
        this.unrecognisedmachineList = [];
      }
      this.spinner.hide();
    },err => {
      this.spinner.hide();
      // TODO: Remove this verification when the back-end gets updated to order by propositionType
      if(obj["orderByColumn"].indexOf("propositionType") !== -1) // Validation to see if the the orderByColumn is ordering propositionType
      {
        return;
      }
      let errors = err.errors == undefined ? err.error : err.errors;
      errors.forEach(element => {
        this.toaster.open({
          text: element.msg,
          type: 'danger',
          position: 'top-right',
          duration: 10000
        });
      });
    });
  }

  openAssignToMarketPopup(element){
    this.assignToMarketFormGroup.reset();
    this.getMarketLookupForAssignMarket('', element.propositionTypeId);//GD2-3874
    this.roleService.setPopupScroll('section-assign-to-market');  
    if (!this.isOpenAssignToMarketPopup) {
      $(".sidebar-mini").addClass("filter-open");
   }
   else {
       $(".sidebar-mini").removeClass("filter-open");
       this.roleService.resetPopupScroll();
   }
    setTimeout(() => {
      this.isOpenAssignToMarketPopup = !this.isOpenAssignToMarketPopup; 
      this.machineDetails = element; 
    }, this.roleService.scrollSetTimeoutTime); 
  }

  closeAssignToMarketPopup(){
    this.roleService.setPopupScroll('section-assign-to-market');
    $(".sidebar-mini").removeClass("filter-open");
    this.roleService.resetPopupScroll();
   
    setTimeout(() => {
      this.isOpenAssignToMarketPopup = false; 
      
    }, this.roleService.scrollSetTimeoutTime);
    
  }
        
  assignToMarket(){
    this.assignToMarketFormGroup.markAllAsTouched();  
    if(this.assignToMarketFormGroup.status === 'INVALID'){
      this.toaster.open({
        text: 'Please provide valid input for all the highlighted fields.',
        type: 'danger',
        position: 'top-right',
        duration: 10000
      });
      
    }else{
    this.spinner.show();
    var body = {
      "machineUnrecognizedId": this.machineDetails.machineUnrecognizedId,
       "marketId": this.assignToMarketFormGroup.controls['marketId'].value
    }    
    this.machineService.assignMakretToUnrecognisedMachine(body).subscribe(response => {
      $(".sidebar-mini").removeClass("filter-open");
      this.roleService.resetPopupScroll()
      setTimeout(() => {
        this.isOpenAssignToMarketPopup = false;   
      }, this.roleService.scrollSetTimeoutTime);
      this.toaster.open({
        text: "Market assigned successfully",
        type: 'success',
        position: 'top-right',
        duration: 10000
      });
      this.getUnrecognisedMachineList();
      this.spinner.hide();
    },err => {
      this.spinner.hide();
      let errors = err.errors == undefined ? err.error : err.errors;
      errors.forEach(element => {
        this.toaster.open({
          text: element.msg,
          type: 'danger',
          position: 'top-right',
          duration: 10000
        });
      });
    });
  }
  }

  searchText(){
    if(this.textLabel == 'Machine List'){
      this.displayAdvancedSearch = false;
      this.getMachineList('search')
    } else if(this.textLabel == 'Unrecognised Machines'){
      this.getUnrecognisedMachineList('search');
    }
  }

  displayFn(): (id: number) => string {
    return (id: number) => {
      if (id) {
        if (this.markets.find(obj => obj.id == id)) {
          let obj = this.markets.find(obj => obj.id == id);
          if (Object.keys(obj).length) {
            return obj.name
          } else {
            return '';
          }
        } else {
          return '';
        }
      }
    }
  }

  requireMatch = (uniqueId,options) => {
    return (control: FormControl) => {
      const selection: any = control.value;
      if (selection != '') {
        var lst = [];
        if (options['assignMarketList'] != undefined && options['assignMarketList'] != null) {
          options['assignMarketList'].subscribe(x => {
            lst = x;
          })
          var obj = lst.filter(x => x.masterDataId == selection);
          if (obj == null || obj.length == 0) {
            return { requireMatch: true };
          }
          else {
            return null;
          }
        } else {
          return null;
        }
      } else { return null; }
}
}

private _filter(value: string): string[] {
  const filterValue = value == null ? null: value.toLowerCase();

  return this.marketsForAssignment.filter(option => option.text.toLowerCase().includes(filterValue));
  }

getTargetWorkingStateLookup() {
  this.targetWorkingState = [];
  this.filter.targetWorkingState = '';
  this.entityService.getLookup('/api/v1/master/machine/getmachineworkingstatus').subscribe(response => {
    if(response != null && response != '') {
      this.targetWorkingState = this.formatSelectOption(response['data']);
    }
  });
}

getSuspendStateLookup() {
  this.suspendReason = [];
  this.filter.suspendReason = '';
  this.entityService.getLookup('/api/v1/master/machine/getsuspendreason').subscribe(response => {
    if(response != null && response != '') {
      this.suspendReason = this.formatSelectOption(response['data']);
    }
  });
}
  
advanceToggle () {
  this.displayAdvancedSearch = !this.displayAdvancedSearch;  
}

applyAdvanceSearch() {
  this.displayAdvancedSearch = false;
  if (this.filter.search != '') {
    this.getMachineList('search');
  }
}
  getPropositionTypes() { //GD2-4430
    this.entityService.getEntityPropositions(this.roleService.entityId).subscribe(response => {
      if (response && response['data']) {
        this.propositionTypes = response['data'].map(({name, propositionTypeId}) => ({
          name,
          id: propositionTypeId
        }))
      }
    });
  }

  private async SetFeatureFlagsConfigurations() {
    this.globalChangesFT = await this.azureService.isGlobalChangesFT();
    this.enableGridImprovementsFT = await this.azureService.isEnableGridImprovementsFT();
  
    this.displayedColumns = this.globalChangesFT ? ['machineSystemId', 'uniqueMachineNumber', 'machineTemplateName', 'propositionType', 'machineSiteName', 'machineStatus', 'connectivityStatus', 'workingStatus','targetWorkingState', 'suspendReason', 'commissioningStatus', 'actions'] : 
                                                   ['machineSystemId', 'uniqueMachineNumber', 'machineTemplateName', 'propositionType', 'machineSiteName', 'machineStatus', 'workingStatus','targetWorkingState', 'suspendReason', 'commissioningStatus', 'actions'];

    if(this.globalChangesFT){
      this.unrecognisedMachineListColumns.unshift('propositionType');
    }
    
    this.showEntityOrRecordName = this.enableGridImprovementsFT ? 'Record' : 'Entity'
    this.displayedColumns = this.enableGridImprovementsFT 
      ? ['machineSiteName', 'uniqueMachineNumber', 'machineReference', 'propositionType', 'machineTemplateName', 'commissioningStatus', 'connectivityStatus', 'workingStatus','targetWorkingState', 'suspendReason', 'customerName', 'sitePostalCode', 'siteCountry', 'machineStatus', 'machineSystemId', 'actions'] 
      : this.displayedColumns;
  }
}

