import { Component, OnInit, Input } from '@angular/core';
import { CreateRemoteUpdateTaskRequest } from 'src/app/components/remote-update-tasks/dtos/create-remote-update-task.model';
import { RemoteUpdateTaskExportService } from 'src/app/components/remote-update-tasks/services/report/remote-update-task-export.service';
import { SafeHtml } from '@angular/platform-browser';
import { RemoteUpdateTaskType } from 'src/app/components/remote-update-tasks/enums/remote-update-task-type.enum';
import { SummaryInformationModel } from 'src/app/components/remote-update-tasks/dtos/summary-info-model';
import { RemoteUpdateTaskLevelNames } from 'src/app/components/remote-update-tasks/dtos/remote-update-task-level-name';
import * as moment from 'moment';
import { SummaryCardModel } from 'src/app/components/remote-update-tasks/dtos/summary-card-model';
import { DrinkState } from 'src/app/components/remote-update-tasks/services/task-properties/dto/drink-dto';
import { Observable } from 'rxjs';
import { RemoteUpdateTaskTypeEntities } from 'src/app/components/remote-update-tasks/dtos/get-remote-update-task-type.model';
@Component({
  selector: 'app-summary-card',
  templateUrl: './summary-card.component.html',
  styleUrls: ['./../../../new-task-step-common.css','./summary-card.component.css']
})
export class SummaryCardComponent implements OnInit {
  @Input() primaryTitle: string = '';
  @Input() primaryValue: string = '';
  @Input() primarySubtitle: string = '';
  @Input() calendarDate: Date;
  @Input() package: string = '';
  @Input() taskType: string = '';
  @Input() footNote: SafeHtml;
  @Input() newTaskRequest: CreateRemoteUpdateTaskRequest = new CreateRemoteUpdateTaskRequest();
  @Input() taskId: number = 0;
  @Input() enableCalendar: boolean = false;
  @Input() enableCalendarLabel: boolean = false;
  @Input() enablePrimaryExportButton: boolean = false;
  @Input() enableSecondaryExportButton: boolean = false;
  @Input() rightSide: boolean;


  public summaryCardsModel: SummaryCardModel[];

  constructor(
    private exportService: RemoteUpdateTaskExportService,
  ) {
    this.getTaskReport = this.getTaskReport.bind(this);
    this.getLevelNamesReport = this.getLevelNamesReport.bind(this);
  }

  ngOnInit(): void { }

  ngOnChanges(): void {
    this.loadSummaryCardsModel();
  }

  public loadSummaryCardsModel() {
    this.summaryCardsModel = this.buildInfoData();
  }

  public getTaskReport(): Observable<any> {
    return this.exportService.downloadReport(this.taskId)
  }

  public showPackage() {
    return this.newTaskRequest.TaskType != RemoteUpdateTaskType.EnableDisableDrinks
  }

  public getLevelNamesReport(): Observable<any> {
    let levelNames = this.buildRemoteUpdateTaskLevelNames();
    return this.exportService.downloadMarketSelectionReport(levelNames);
  }

  public buildRemoteUpdateTaskLevelNames(): RemoteUpdateTaskLevelNames {
    var levelNames: RemoteUpdateTaskLevelNames;
    levelNames = {
      Level1: this.mapEntitiesName(this.newTaskRequest.ScreenSelectedLevel1),
      Level2: this.mapEntitiesName(this.newTaskRequest.ScreenSelectedLevel2),
      Level3: this.mapEntitiesName(this.newTaskRequest.ScreenSelectedLevel3)
    };
    return levelNames;
  }

  private mapEntitiesName(remoteEntities?: RemoteUpdateTaskTypeEntities[]) {
    if (remoteEntities === null || remoteEntities === undefined || remoteEntities.length === 0)
      return [];

    return remoteEntities.map(x => x.name);
  }


  public buildInfoData(): SummaryCardModel[] {
    let title: string;
    let infos: SummaryInformationModel[] = [];
    let cardModels: SummaryCardModel[] = [];
    if (this.newTaskRequest.TaskType === RemoteUpdateTaskType.CertificateManagement) {
      if (this.rightSide) {
        let calendarDataLabel: string = moment(this.newTaskRequest.ScheduleOn).format('DD/MM/YYYY');
        infos.push(new SummaryInformationModel('Scheduled for: ', [calendarDataLabel]));
        cardModels.push(new SummaryCardModel('Certificate Command:', [{
          isChip: true,
          title: '',
          itens: [this.newTaskRequest.TaskProperty]
        }], 1));
      }

      return cardModels
    }
    if (this.newTaskRequest.TaskType != RemoteUpdateTaskType.EnableDisableDrinks) {
      title = 'Task Parameters';  
      
      infos.push(new SummaryInformationModel('Hierarchy Level: ', [this.newTaskRequest.EntityDeployLevel]));
      if (this.enableCalendarLabel) {
        let calendarDataLabel: string = moment(this.newTaskRequest.ScheduleOn).format('DD/MM/YYYY');
        infos.push(new SummaryInformationModel('Scheduled for: ', [calendarDataLabel]));
      }
      cardModels.push(new SummaryCardModel('Task Parameters', infos));
    } else {
      if (!this.rightSide) {
        infos.push(new SummaryInformationModel('Market', this.mapEntitiesName(this.newTaskRequest.ScreenSelectedLevel1), true));
        infos.push(new SummaryInformationModel('L2',this.mapEntitiesName(this.newTaskRequest.ScreenSelectedLevel2), true));
        infos.push(new SummaryInformationModel('L3', this.mapEntitiesName(this.newTaskRequest.ScreenSelectedLevel3), true));
        cardModels.push(new SummaryCardModel('Market Selection Parameters', infos, 3));
      } else {
        let enableDrinksNames: string[] = [];
        let disableDrinksNames: string[] = [];
        let enableDrinks: SummaryInformationModel[] = [];
        let disableDrinks: SummaryInformationModel[] = [];
        for (let drink of this.newTaskRequest.DrinkList) {
          switch (drink.state) {
            case DrinkState.Enabled: {
              enableDrinksNames.push(drink.name);
              break;
            }
            case DrinkState.Disabled: {
              disableDrinksNames.push(drink.name);
              break;
            }
          }
        }
        enableDrinks.push(new SummaryInformationModel('', enableDrinksNames, true));
        cardModels.push(new SummaryCardModel('Drinks to enable:', enableDrinks, 5));
        disableDrinks.push(new SummaryInformationModel('', disableDrinksNames, true));
        let disableDisable: SummaryCardModel = new SummaryCardModel('Drinks to disable:', disableDrinks, 5);
        cardModels.push(disableDisable);
      }
    }

    return cardModels;
  }
}
